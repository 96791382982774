import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_appointment.css";

function Add_appointment({ onClose }) {
  const [modalAdd_appointmentIsOpen, setAdd_appointmentModalIsOpen] =
    useState("");

  const [isAddingappointment, setIsAddingappointment] = useState(false);

  const closeAdd_appointment = () => {
    setAdd_appointmentModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }

  return (
    <div>
      {modalAdd_appointmentIsOpen && (
        <add_appointment onClose={closeAdd_appointment} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "auto",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add Appointment</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Partner Name<font color="red">*</font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder="Enter Partner Name"
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputDocument">
                    Icon
                  </label>
                  <br />
                  <input
                    type="file"
                    id="selectedImg"
                    name="document"
                    accept="image/*"
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Email
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="email"
                    placeholder="Enter Email"
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Link
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="link"
                    placeholder="Enter Link"
                  />
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingappointment === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#51cbce ", border: "none" }}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_appointment;
