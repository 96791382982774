import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_location.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { addLocationArea, getAdminLocationCity } from "../../Api/apiUrls";

function Add_location({ onClose }) {
  const [modalAdd_locationIsOpen, setAdd_locationModalIsOpen] = useState("");
  const [locationStatus, setLocationStatus] = useState("");
  const [selectedCity, setSelectedCity] = useState([]);
  const [locationCity, setLocationCity] = useState("");
  const [locationName, setLocationName] = useState("");
  const [isAddinglocation, setIsAddinglocation] = useState(false);
  const [city, setCity] = useState([]);

  const closeAdd_location = () => {
    setAdd_locationModalIsOpen(false);
  };
  const Status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  function reloadPage() {
    window.location.reload();
  }
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  useEffect(() => {
    fetch(`${getAdminLocationCity}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (Array.isArray(data.data.LocationCity)) {
            setCity(data.data.LocationCity);
          } else {
            console.error("City data is not an array:", data.data.LocationCity);
          }
        } else {
          console.error("Error fetching categories:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);
  const handleStatusChange = (selectedOption) => {
    setLocationStatus(selectedOption.value);
    console.log(selectedOption.value);
  };
  const cityOptions = city.map((city) => ({
    value: city.id,
    label: city.name,
  }));

  const handleCityChange = (selectedOptions) => {
    console.log(selectedOptions);
    setSelectedCity([selectedOptions]);
    // setaa([selectedOptions.label])

    console.log(selectedOptions);
  };
  async function addLocation(
    locationName,
    locationStatus,
    locationCity,
    selectedCity
  ) {
    try {
      const formData = new FormData();
      formData.append("name", locationName);
      formData.append("city", selectedCity[0].value);
      formData.append("status", locationStatus);

      // console.log(categoryHomepage);
      const response = await fetch(addLocationArea, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        return true; // Category added successfully
      } else {
        throw new Error("Failed to add location");
      }
    } catch (error) {
      console.error("Error adding location:", error);
      return false; // Category addition failed
    }
  }

  const handleAddCity = async () => {
    if (!locationName) {
      alert("Please fill in all fields");
      return;
    }

    setIsAddinglocation(true);

    const result = await addLocation(
      locationName,
      locationStatus,
      locationCity,
      selectedCity
    );

    setIsAddinglocation(false);

    if (result) {
      alert("Location Added Successfully");
      // setSelectedPackage("");
      console.log(locationName);
      setLocationName("");
      window.location.reload();
      // setIconFile(null);
      onClose(); // Close the modal
    } else {
      alert("Error Adding area");
    }
  };
  return (
    <div>
      {modalAdd_locationIsOpen && <add_location onClose={closeAdd_location} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "auto",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add Area</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name
                  </label>
                  <input
                    value={locationName}
                    onChange={(e) => setLocationName(e.target.value)}
                    class="form-control"
                    id="inputFullName"
                    type="text"
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    City
                  </label>
                  <Select
                    options={cityOptions}
                    //  value={selectedCategories}
                    value={selectedCity.length > 0 ? selectedCity[0] : null}
                    // value={selectedCategories.length > 0 ? selectedCategories[0] : null}
                    onChange={handleCityChange}
                  ></Select>
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Category Status
                  </label>
                  <Select
                    onChange={handleStatusChange}
                    options={Status}
                  ></Select>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddinglocation === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAddCity}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_location;
