import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_workshop.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { UpdateAdminWorkshopFAQ } from "../../Api/apiUrls";
import { UpdateAdminWorkshopSlot } from "../../Api/apiUrls";

import {
  UpdateAdminWorkshop,
  API_URL,
  getCategories,
  getAdminSubCategories,
  getAdminLocationArea,
  getAdminLocationCity,
  addFaqPage,
  getFaqByWorkshopId,
  getFAQById,
  deleteFAQById,
  addAdminWorkshopSlot,
  getWorkshopSlotByWorkshopId,
  deleteWorkshopSlotById,
  getAdminWorkshopSlotById,
} from "../../Api/apiUrls";
function Update_workshop({
  workshopId,
  workshopName,
  workshopShort,
  workshopDescription,
  workshopKnowledge,
  workshopYouGet,
  workshopBring,
  workshopSuitable,
  wokshopCategory,
  wokshopSubCategory,
  workshopCity,
  workshopLocation,
  workshopseats,
  workshopDuration,
  workshopPrice,
  workshopTherapy,
  workshopOutstation,
  workshopAddress,
  workshopPostal,
  workshopGoogle,
  workshopEmail,
  workshopPhone,
  workshopWebsite,
  workshopStatus,
  workshopInstructor,
  workshopOnline,
  onClose,
}) {
  console.log(workshopId);
  console.log(workshopName);
  console.log(workshopShort);
  console.log(workshopCity);
  console.log(wokshopCategory);
  const [modalUpdate_workshopIsOpen, setUpdate_workshopModalIsOpen] =
    useState("");
  const [showFields, setShowFields] = useState(false);
  const [showFields2, setShowFields2] = useState(false);
  const [dateTime, setDateTime] = useState("");
  const [Question, setQuestion] = useState("");
  const [Answer, setAnswer] = useState("");
  const [isAddingFaq, setIsAddingFaq] = useState(false);
  const [newFaqAnswer, setNewFaqAnswer] = useState(Answer);
  const [newFaqQuestion, setNewFaqQuestion] = useState(Question);
  const [id, setId] = useState("");
  const [showFields3, setShowFields3] = useState(false);
  const [isUpdatingFaq, setIsUpdatingFaq] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingWorkshop, setIsUpdatingWorkshop] = useState(true);

  const [bookingDate, setBookingDate] = useState("");
  const [bookingStartTime, setBookingStartTime] = useState("");
  const [bookingEndTime, setBookingEndTime] = useState("");
  const [bookingPrice, setBookingPrice] = useState("");
  const [bookingCapacity, setBookingCapacity] = useState("");
  const [isAddingBookingSlot, setIsAddingBookingSlot] = useState(false);
  const [bookingNewDate, setBookingNewDate] = useState("");
  const [bookingNewStartTime, setBookingNewStartTime] = useState("");
  const [bookingNewEndTime, setBookingNewEndTime] = useState("");
  const [bookingNewPrice, setBookingNewPrice] = useState("");
  const [bookingNewCapacity, setBookingNewCapacity] = useState("");
  const [id2, setId2] = useState("");
  const [isUpdatingSlot, setIsUpdatingSlot] = useState(false);
  const [showFields4, setShowFields4] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [location, setLocation] = useState([]);
  const [city, setCity] = useState([]);
  const [filteredAreas, setFilteredAreas] = useState([]);
  // const [workshopStatus, setWorkshopStatus] = useState("");
  const [therapyChange, setTherapy] = useState("");
  const [outstationChange, setOutstation] = useState("");
  const [workshopNewName, setNewWorkshopName] = useState(workshopName);
  const [workshopNewShortDescription, setNewWorkshopShortDescription] =
    useState(workshopShort);
  const [workshopNewDescription, setNewWorkshopDescription] =
    useState(workshopDescription);
  const [workshopNewKnowledge, setNewWorkshopKnowledge] =
    useState(workshopKnowledge);
  const [workshopNewWhatYouGet, setNewWorkshopWhatYouGet] =
    useState(workshopYouGet);
  const [workshopNewWhatBring, setNewWorkshopWhatBring] =
    useState(workshopBring);
  const [workshopNewSuitable, setNewWorkshopSuitable] =
    useState(workshopSuitable);
  const [workshopNewSeats, setNewWorkshopSeats] = useState(workshopseats);
  const [workshopNewDuration, setNewWorkshopDuration] =
    useState(workshopDuration);
  const [workshopNewPrice, setNewWorkshopPrice] = useState(workshopPrice);
  const [workshopNewAddress, setNewWorkshopAddress] = useState(workshopAddress);
  const [workshopNewPostalCode, setNewWorkshopPostalCode] =
    useState(workshopPostal);
  const [workshopNewGoogleCode, setNewWorkshopGoogleCode] =
    useState(workshopGoogle);
  const [workshopNewEmail, setNewWorkshopEmail] = useState(workshopEmail);
  const [workshopNewPhone, setNewWorkshopPhone] = useState(workshopPhone);
  const [workshopNewWebsite, setNewWorkshopWebsite] = useState(workshopWebsite);
  const [newWorkshopStatus, setNewWorkshopStatus] = useState(workshopStatus);
  const [newWorkshopTherapy, setNewWorkshopTherapy] = useState(workshopTherapy);
  const [newWorkshopOutstation, setNewWorkshopOutstation] =
    useState(workshopOutstation);
    const [newWorkshopOnline, setNewWorkshopOnline] =
    useState(workshopOnline);
  const [instructors, setInstructors] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [onlineChange, setOnline] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [filteredSubCategories, setFilteredSubCategories] = useState([]);

  const closeUpdate_workshop = () => {
    setUpdate_workshopModalIsOpen(false);
  };
  const filter = [{ value: "1", label: "1" }];
  const therapy = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const outsation = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const online = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const homepage = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  function getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    // const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  }
  const handleAddClick = (event) => {
    event.preventDefault();
    const currentDateTime = getCurrentDateTime();
    setDateTime(currentDateTime);
    console.log(currentDateTime);
    setShowFields(true);
  };

  const handleCancelClick = () => {
    setShowFields(false);
  };

  const handleAddClick2 = (event) => {
    event.preventDefault();
    setShowFields2(true);
  };

  const handleCancelClick2 = () => {
    setShowFields2(false);
  };
  const handleCancelClick3 = () => {
    setShowFields3(false);
  };
  const handleCancelClick4 = () => {
    setShowFields4(false);
  };

  function reloadPage() {
    window.location.reload();
  }
  const handleStatusChange = (selectedOption) => {
    setNewWorkshopStatus(selectedOption);
  };
  const handleTherapyChange = (selectedOption) => {
    setNewWorkshopTherapy(selectedOption);
  };
  const handleOutstationChange = (selectedOption) => {
    setNewWorkshopOutstation(selectedOption);
  };
  const handleOnlineChange = (selectedOption) => {
    setNewWorkshopOnline(selectedOption);
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  // -----------------get category-------------------------------------------
  const [selectedCategories, setSelectedCategories] = useState(wokshopCategory);
  useEffect(() => {
    fetch(`${getCategories}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (Array.isArray(data.data.Categories)) {
            // Filter categories by type 'Workshop'
            const workshopCategories = data.data.Categories.filter(
              (category) => category.type === "Workshop"
            );
            setCategories(workshopCategories);

            if (wokshopCategory) {
              const initialCategory = workshopCategories.find(
                (category) => category.id === wokshopCategory.id
              );
              // setSelectedCategories(initialCategory ? [initialCategory] : []);
            }
          } else {
            console.error(
              "Categories data is not an array:",
              data.data.Categories
            );
          }
        } else {
          console.error(
            "Categories data is not an array:",
            data.data.Categories
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [wokshopCategory]);

  const categoryOptions = categories.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategory(selectedOptions);
    setSelectedCategories(selectedOptions);
  };
  // -----------------get subCategory-------------------------------------------
  const [selectedSubCategories, setSelectedSubCategories] =
    useState(wokshopSubCategory);
  useEffect(() => {
    fetch(`${getAdminSubCategories}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (Array.isArray(data.data.SubCategories)) {
            // Filter categories by type 'Workshop'
            const workshopSubCategories = data.data.SubCategories.filter(
              (SubCategories) => SubCategories.type === "Workshop"
            );
            setSubCategories(workshopSubCategories);
          } else {
            console.error(
              "SubCategories data is not an array:",
              data.data.SubCategories
            );
          }
        } else {
          console.error("Error fetching categories:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [wokshopSubCategory]);
  useEffect(() => {
    if (selectedCategory) {
      const filteredSubCategories = subCategories.filter(
        (subCategory) => subCategory.category.id === selectedCategory.value
      );
      // console.log(subCategories.category);
      setFilteredSubCategories(filteredSubCategories);
      console.log(filteredSubCategories);
    } else {
      setFilteredSubCategories([]);
    }
  }, [selectedCategory, subCategories]);
  const subCategoryOptions = filteredSubCategories.map((subCategory) => ({
    value: subCategory.id,
    label: subCategory.name,
  }));

  const handleSubCategoryChange = (selectedOptions) => {
    setSelectedSubCategories(selectedOptions);
  };
  // ---------------------------get area---------------------------------
  const [selectedLocation, setSelectedLocation] = useState(workshopLocation);

  useEffect(() => {
    fetch(`${getAdminLocationArea}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (Array.isArray(data.data.LocationArea)) {
            setLocation(data.data.LocationArea);
          } else {
            console.error("City data is not an array:", data.data.LocationArea);
          }
        } else {
          console.error("Error fetching categories:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const areaOptions = filteredAreas.map((location) => ({
    value: location.id,
    label: location.name,
  }));
  const handleAreaChange = (selectedOptions) => {
    console.log(selectedOptions);
    setSelectedLocation(selectedOptions);

    console.log(selectedOptions);
  };

  // -----------------get city-------------------------------------------
  const [selectedCity, setSelectedCity] = useState(workshopCity);
  useEffect(() => {
    fetch(`${getAdminLocationCity}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (Array.isArray(data.data.LocationCity)) {
            setCity(data.data.LocationCity);
          } else {
            console.error("City data is not an array:", data.data.LocationCity);
          }
        } else {
          console.error("Error fetching categories:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const cityOptions = city.map((city) => ({
    value: city.id,
    label: city.name,
  }));
  useEffect(() => {
    if (selectedCity && selectedCity.value) {
      // Check if a city is selected
      console.log(selectedCity);
      const filteredAreasResult = location.filter(
        (location) => location.city.id === selectedCity.value
      );
      setFilteredAreas(filteredAreasResult);
    }
  }, [selectedCity, location]);
  const handleCityChange = (selectedOptions) => {
    console.log(selectedOptions);
    setSelectedCity(selectedOptions);

    console.log(selectedOptions);
  };

  // --------------------------------------add  Faqs-------------------------------------------------------------
  const [faq, setFaq] = useState(false);
  const handleAddFAQ = async (event) => {
    try {
      event.preventDefault();

      if (!Question) {
        alert("Please fill in all fields");
        return;
      }

      setIsAddingFaq(true);

      const formData = new FormData();
      formData.append("temp_id", dateTime);
      formData.append("type", "Workshop");
      formData.append("question", Question);
      formData.append("answer", Answer);
      formData.append("workshop", workshopId);

      const addResponse = await fetch(`${addFaqPage}`, {
        method: "POST",
        body: formData,
      });

      if (!addResponse.ok) {
        alert("Failed to add FAQ's");
        return;
      } else {
        console.log(workshopId);
      }
      const getResponse = await fetch(`${getFaqByWorkshopId}${workshopId}`);

      if (!getResponse.ok) {
        alert("Failed to fetch FAQs");
        return;
      }

      const getData = await getResponse.json();
      const faqData = getData.data.FAQ || [];

      setFaq(faqData);
      setShowFields(false);
      setQuestion("");
      setAnswer("");
    } catch (error) {
      console.error("Error handling FAQ:", error);
    } finally {
      setIsAddingFaq(false);
    }
  };

  // ---------------------------------- get faqs------------------------------------------------------------------------

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${getFaqByWorkshopId}${workshopId}`);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const faqData = data.data.FAQ || [];

        setFaq(faqData);
        setShowFields(false);
        setQuestion("");
        setAnswer("");
      } catch (error) {
        console.error("Error handling FAQ:", error);
      } finally {
        setIsAddingFaq(false);
      }
    };

    fetchData();
  }, [workshopId]);

  // ------------------------------------------get faq by id-----------------------------------------------

  const handleClick3 = (id) => {
    // event.preventDefault();

    fetch(`${getFAQById}${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const bookingFaq = data.data.FAQ;
        console.log("each", bookingFaq);
        // setEachSlot(bookingFaq);
        setNewFaqAnswer(bookingFaq.answer);
        setNewFaqQuestion(bookingFaq.question);
        // setDatetime(bookingFaq.temp_id);
        setId(bookingFaq.id);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });

    setShowFields3(true);
  };

  // --------------------------------------------update faqs-----------------------------------------------------------------------------

  const handleUpdateFaq = async (id) => {
    try {
      setIsUpdatingFaq(true);

      const formData = new FormData();
      formData.append("faq_id", id);
      // formData.append("temp_id", datetime);
      formData.append("question", newFaqQuestion);
      formData.append("answer", newFaqAnswer);

      const updateResponse = await fetch(UpdateAdminWorkshopFAQ, {
        method: "PUT",
        body: formData,
      });

      if (!updateResponse.ok) {
        alert("Failed to update FAQ");
        return;
      }

      // Trigger get API after update
      const getResponse = await fetch(`${getFaqByWorkshopId}${workshopId}`);

      if (!getResponse.ok) {
        alert("Failed to fetch FAQs");
        return;
      }

      const getData = await getResponse.json();
      const updatedFaqData = getData.data.FAQ || [];

      setFaq(updatedFaqData);
      setShowFields3(false);
    } catch (error) {
      console.error("Error updating FAQ:", error);
    } finally {
      setIsUpdatingFaq(false);
    }
  };

  // ----------------------------delete faqs-----------------------------------------------

  const handleDeleteFaq = (id) => {
    fetch(`${deleteFAQById}${id}`, {
      method: "DELETE",
      headers: {
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Check if the delete operation was successful
        if (data.success) {
          // Trigger the get API after successful deletion
          return fetch(`${getFaqByWorkshopId}${workshopId}`);
        } else {
          // Handle the error if the delete operation was not successful
          console.error("Error deleting FAQ:", data.message);
        }
      })
      .then((getResponse) => {
        // Check if the get API response is successful
        if (getResponse && getResponse.ok) {
          return getResponse.json();
        } else {
          throw new Error("Failed to fetch FAQs");
        }
      })
      .then((getData) => {
        // Update the table with the fetched data
        const updatedFaqData = getData.data.FAQ || [];
        setFaq(updatedFaqData);
      })
      .catch((error) => {
        console.error("Error handling FAQ:", error);
      });
  };

  // ------------------------add workshop slot ------------------------------------------------------------

  const [bookingSlot, setBookingSlot] = useState([]);
  const handleAddBookingSlot = async (event) => {
    try {
      event.preventDefault();

      if (!bookingDate) {
        alert("Please fill in all fields");
        return;
      }

      setIsAddingBookingSlot(true);

      const formData = new FormData();
      formData.append("date", bookingDate);
      formData.append("startTime", bookingStartTime);
      formData.append("endTime", bookingEndTime);
      formData.append("price", bookingPrice);
      formData.append("temp_id", dateTime);
      formData.append("totalSeats", bookingCapacity);
      formData.append("availableSeats", bookingCapacity);
      formData.append("workshop", workshopId);
      formData.append("changed_by", "Admin");

      const addResponse = await fetch(`${addAdminWorkshopSlot}`, {
        method: "POST",
        body: formData,
      });

      if (!addResponse.ok) {
        alert("Failed to add Slot");
        return;
      }
      const getResponse = await fetch(
        `${getWorkshopSlotByWorkshopId}${workshopId}`
      );

      if (!getResponse.ok) {
        alert("Failed to fetch slots");
        return;
      }

      const getData = await getResponse.json();
      const bookingSlot = getData.data.WorkshopSlot || [];

      setBookingSlot(bookingSlot);
      setShowFields2(false);
      setBookingDate("");
      setBookingStartTime("");
      setBookingEndTime("");
      setBookingPrice("");
      setBookingCapacity("");
    } catch (error) {
      console.error("Error handling FAQ:", error);
    } finally {
      setIsAddingBookingSlot(false);
    }
  };

  // ---------------------------------- get workshop slot------------------------------------------------------------------------

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${getWorkshopSlotByWorkshopId}${workshopId}`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const getResponse = await response.json(); // Corrected this line
        const bookingSlot = getResponse.data.WorkshopSlot || []; // Corrected this line

        setBookingSlot(bookingSlot);
        setShowFields2(false);
        setBookingDate("");
        setBookingStartTime("");
        setBookingEndTime("");
        setBookingPrice("");
        setBookingCapacity("");
      } catch (error) {
        console.error("Error handling FAQ:", error);
      } finally {
        setIsAddingBookingSlot(false);
      }
    };

    fetchData();
  }, [workshopId]);

  // ---------------------------------get workshop slot by id --------------------------------------------------------

  const handleClick4 = (id2) => {
    // event.preventDefault();

    fetch(`${getAdminWorkshopSlotById}${id2}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const bookingEachSlot = data.data.WorkshopSlot;
        setBookingNewDate(bookingEachSlot.date);
        setBookingNewStartTime(bookingEachSlot.startTime);
        setBookingNewEndTime(bookingEachSlot.endTime);
        setBookingNewPrice(bookingEachSlot.price);
        setBookingNewCapacity(bookingEachSlot.totalSeats);
        // setDatetime(bookingEachSlot.temp_id);
        setId2(bookingEachSlot.id);
        console.log(bookingEachSlot.id);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });

    setShowFields4(true);
  };

  // -------------------------update wokshop slot ----------------------------------------------------------------

  const handleUpdateWorkshop = async (id2) => {
    try {
      setIsUpdatingSlot(true);

      const formData = new FormData();
      formData.append("WorkshopSlot_id", id2);
      formData.append("temp_id", dateTime);
      formData.append("date", bookingNewDate);
      formData.append("startTime", bookingNewStartTime);
      formData.append("endTime", bookingNewEndTime);
      formData.append("price", bookingNewPrice);
      formData.append("totalSeats", bookingNewCapacity);
      formData.append("changed_by", "Admin");

      const updateResponse = await fetch(UpdateAdminWorkshopSlot, {
        method: "PUT",
        body: formData,
      });

      if (!updateResponse.ok) {
        alert("Failed to update slot");
        return;
      }

      // Trigger get API after update
      const getResponse = await fetch(
        `${getWorkshopSlotByWorkshopId}${workshopId}`
      );

      if (!getResponse.ok) {
        alert("Failed to fetch slots");
        return;
      }

      const getData = await getResponse.json();
      const bookingSlot = getData.data.WorkshopSlot || [];

      setBookingSlot(bookingSlot);
      setShowFields4(false);
    } catch (error) {
      console.error("Error handling slots:", error);
    } finally {
      setIsUpdatingSlot(false);
    }
  };

  // ---------------------------delete workshop slot ------------------------------------

  const handleDeleteWorkshop = (id2) => {
    fetch(`${deleteWorkshopSlotById}${id2}&changed_by=Admin`, {
      method: "DELETE",
      headers: {
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Check if the delete operation was successful
        if (data.success) {
          // Trigger the get API after successful deletion
          return fetch(`${getWorkshopSlotByWorkshopId}${workshopId}`);
        } else {
          // Handle the error if the delete operation was not successful
          alert( data.message);
        }
      })
      .then((getResponse) => {
        // Check if the get API response is successful
        if (getResponse && getResponse.ok) {
          return getResponse.json();
        } else {
          throw new Error("Failed to fetch slot");
        }
      })
      .then((getData) => {
        const bookingSlot = getData.data.WorkshopSlot || [];
        setBookingSlot(bookingSlot);
      })
      .catch((error) => {
        console.error("Error handling slot:", error);
      });
  };
  useEffect(() => {
    // Fetch data from the API
    fetch(`${API_URL}/user/get-instructor/`)
      .then((response) => response.json())
      .then((data) => {
        // Extract relevant data from the response
        const options = data.data.users.map((user) => ({
          value: user.id,
          label: user.name,
          image: `${API_URL}/${user.profile_image}`,
        }));
        setInstructors(options);
        setFilteredOptions(options); // Initially, set filtered options to all options
        setIsLoading(false);
      })
      .catch((error) => console.error("Error fetching instructors:", error));
  }, []);

  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
    // filterOptions(newValue);
  };

  // const filterOptions = (inputValue) => {
  //   const filtered = instructors.filter((option) =>
  //     option.label.toLowerCase().includes(inputValue.toLowerCase())
  //   );

  //   setFilteredOptions(filtered);
  //   console.log(filtered);
  // };

  const [selectedInstructor, setSelectedInstructor] =
    useState(workshopInstructor);

  const handleInstructorChange = (selectedOption) => {
    setSelectedInstructor(selectedOption);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // -------------------------update workshop-------------------------------------------------------------------------

  const handleUpdate = () => {
    setIsUpdatingWorkshop(true);

    const formData = new FormData();
    formData.append("Workshop_id", workshopId);
    formData.append("name", workshopNewName);
    formData.append("description", workshopNewDescription);
    formData.append("short_description", workshopNewShortDescription);
    formData.append("knowledge_require", workshopNewKnowledge);
    formData.append("what_you_will_get", workshopNewWhatYouGet);
    formData.append("what_to_bring", workshopNewWhatBring);
    formData.append("suitable_for", workshopNewSuitable);
    formData.append("total_seats", workshopNewSeats);
    formData.append("duration_in_hour", workshopNewDuration);
    formData.append("price", workshopNewPrice);
    formData.append("therapy", newWorkshopTherapy.label);
    formData.append("outstation", newWorkshopTherapy.label);
    formData.append("online", newWorkshopOnline.label);
    formData.append("address", workshopNewAddress);
    formData.append("city", selectedCity.value);
    formData.append("area", selectedLocation.value);
    formData.append("pincode", workshopNewPostalCode);
    formData.append("google_location_link", workshopNewGoogleCode);
    formData.append("email", workshopNewEmail);
    formData.append("mobileNumber", workshopNewPhone);
    formData.append("website", workshopNewWebsite);
    
    formData.append("category", selectedCategories.value);

    formData.append("subcategory", selectedSubCategories.value);
    formData.append("status", newWorkshopStatus.label);
    formData.append("workshopInstructor", selectedInstructor.value);
    formData.append("changed_by", "Admin");
    // formData.append("city", selectedCity[0].value);
    // if (selectedLocation && selectedLocation.length > 0) {
    //   formData.append("area", selectedLocation[0].value);
    // } else {
    //   console.error("selectedLocation is undefined or empty");
    // }

    fetch(UpdateAdminWorkshop, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated");
          setIsUpdatingWorkshop(false);

          window.location.reload();
          onClose();
        } else {
          console.error("Error updating category:", data.message);
          setIsUpdatingWorkshop(false);
        }
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        setIsUpdatingWorkshop(false);
      });
  };

  return (
    <div>
      {modalUpdate_workshopIsOpen && (
        <Update_workshop onClose={closeUpdate_workshop} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update Workshop</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Title of Workshop:
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopNewName}
                    onChange={(e) => setNewWorkshopName(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Short Description:
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopNewShortDescription}
                    onChange={(e) =>
                      setNewWorkshopShortDescription(e.target.value)
                    }
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description:
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopNewDescription}
                    onChange={(e) => setNewWorkshopDescription(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Knowledge Required:
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopNewKnowledge}
                    onChange={(e) => setNewWorkshopKnowledge(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    What you'll get:
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopNewWhatYouGet}
                    onChange={(e) => setNewWorkshopWhatYouGet(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    What to bring:
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopNewWhatBring}
                    onChange={(e) => setNewWorkshopWhatBring(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Suitable for:
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=" "
                    value={workshopNewSuitable}
                    onChange={(e) => setNewWorkshopSuitable(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Category <font color="red"></font>
                  </label>

                  <Select
                    options={categoryOptions}
                    // value={
                    //   selectedCategories.length > 0
                    //     ? selectedCategories[0]
                    //     : null
                    // }
                    value={selectedCategories}
                    onChange={handleCategoryChange}
                  ></Select>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Sub-Category <font color="red"></font>
                  </label>

                  <Select
                    options={subCategoryOptions}
                    value={selectedSubCategories}
                    onChange={handleSubCategoryChange}
                  ></Select>
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Max People:
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopNewSeats}
                    onChange={(e) => setNewWorkshopSeats(e.target.value)}
                  />
                  <div class="form-group">
                    <label class="small mb-1" for="inputFullName">
                      Duration in Hrs.
                    </label>
                    <input
                      class="form-control"
                      id="inputFullName"
                      type="text"
                      placeholder=""
                      value={workshopNewDuration}
                      onChange={(e) => setNewWorkshopDuration(e.target.value)}
                    />

                    <div class="valid-feedback">Thank you!</div>
                  </div>

                  <div class="form-group">
                    <label class="small mb-1" for="inputFullName">
                      Fees:
                    </label>
                    <input
                      class="form-control"
                      id="inputFullName"
                      type="text"
                      placeholder=""
                      value={workshopNewPrice}
                      onChange={(e) => setNewWorkshopPrice(e.target.value)}
                    />

                    <div class="valid-feedback">Thank you!</div>
                  </div>
                  <div class="form-group">
                    <label class="small mb-1" for="inputFullName">
                      Therapy
                    </label>
                    <div class="child ml-2">
                      <Select
                        value={newWorkshopTherapy}
                        onChange={handleTherapyChange}
                        options={therapy}
                      ></Select>
                    </div>

                    <div class="valid-feedback">Thank you!</div>
                  </div>
                  <div class="form-group">
                    <label class="small mb-1" for="inputFullName">
                      Outstation
                    </label>
                    <div class="child ml-2">
                      <Select
                        value={newWorkshopOutstation}
                        onChange={handleOutstationChange}
                        options={outsation}
                      ></Select>
                    </div>

                    <div class="valid-feedback">Thank you!</div>
                  </div>

                  <div class="form-group">
                    <label class="small mb-1" for="inputFullName">
                      Address Line1:
                    </label>
                    <input
                      class="form-control"
                      id="inputFullName"
                      type="text"
                      placeholder=""
                      value={workshopNewAddress}
                      onChange={(e) => setNewWorkshopAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Select City<font color="red"></font>
                  </label>
                  <Select
                    options={cityOptions}
                    value={selectedCity}
                    onChange={handleCityChange}
                  ></Select>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Select Area <font color="red"></font>
                  </label>
                  <Select
                    options={areaOptions}
                    value={selectedLocation}
                    onChange={handleAreaChange}
                  ></Select>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Postal Code:
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopNewPostalCode}
                    onChange={(e) => setNewWorkshopPostalCode(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Google Maps Link
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopNewGoogleCode}
                    onChange={(e) => setNewWorkshopGoogleCode(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Email:
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopNewEmail}
                    onChange={(e) => setNewWorkshopEmail(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Phone:
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopNewPhone}
                    onChange={(e) => setNewWorkshopPhone(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Website:
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopNewWebsite}
                    onChange={(e) => setNewWorkshopWebsite(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div>
                  <div className="form-group">
                    <label className="small mb-1" htmlFor="inputFullName">
                      FAQ's
                    </label>
                    <button
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        borderRadius: "4px",
                        backgroundColor: "#000",
                        color: "#fff",
                      }}
                      onClick={handleAddClick}
                    >
                      + Add
                    </button>
                  </div>
                  {showFields && (
                    <>
                      <div
                        class="col-md-12"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="form-group col-md-6">
                          <label className="small mb-1" htmlFor="inputFullName">
                            Enter Question
                          </label>
                          <input
                            className="form-control"
                            id="inputFullName"
                            type="text"
                            value={Question}
                            onChange={(e) => setQuestion(e.target.value)}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="small mb-1" htmlFor="inputFullName">
                            Enter Answer
                          </label>
                          <input
                            className="form-control"
                            id="inputFullName"
                            type="text"
                            value={Answer}
                            onChange={(e) => setAnswer(e.target.value)}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", marginBottom: "20px" }}>
                        <button
                          type="submit"
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            borderRadius: "4px",
                            backgroundColor: "#000",
                            color: "#fff",
                            border: "none",
                            padding: "5px 20px",
                          }}
                          onClick={handleAddFAQ}
                        >
                          Add
                        </button>
                        <button
                          type="button"
                          onClick={handleCancelClick}
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            borderRadius: "4px",
                            backgroundColor: "lightgrey",
                            color: "#000",
                            border: "none",
                            padding: "5px 20px",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <div className="table-responsive">
                  {Array.isArray(faq) && faq.length > 0 ? (
                    <table
                      className="table table-striped"
                      style={{ padding: "0rem" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">Sr.No</th>
                          <th scope="col">Question</th>
                          <th scope="col">Answer</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {faq.map((faqItem, index) => (
                          <tr key={faqItem.id}>
                            <th scope="row">{index + 1}</th>
                            <td>
                              <b>{faqItem.question || "-"}</b>
                            </td>
                            <td>
                              <b>{faqItem.answer || "-"}</b>
                            </td>
                            <td>
                              <div className="social-links mt-2">
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleClick3(faqItem.id)}
                                >
                                  <i className="bi bi-pencil"></i>
                                </a>
                                &nbsp;&nbsp;
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleDeleteFaq(faqItem.id)}
                                >
                                  <i className="bi bi-trash3"></i>
                                </a>
                                &nbsp;
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <h3 style={{ textAlign: "center" }}></h3>
                  )}
                </div>

                {showFields3 && (
                  <div>
                    <>
                      <div>
                        <div
                          class="col-md-12"
                          style={{ display: "flex", paddingLeft: "0px" }}
                        >
                          <div
                            class="form-group col-md-6"
                            style={{ paddingLeft: "0px" }}
                          >
                            <label class="small mb-1" for="inputFullName">
                              Question<font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="text"
                              // value={eachSlot.startDate}
                              value={newFaqQuestion}
                              onChange={(e) =>
                                setNewFaqQuestion(e.target.value)
                              }
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="small mb-1" for="inputFullName">
                              Answer <font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="text"
                              // value={eachSlot.startTime}
                              value={newFaqAnswer}
                              onChange={(e) => setNewFaqAnswer(e.target.value)}
                            />
                          </div>
                        </div>

                        <div style={{ display: "flex", marginBottom: "20px" }}>
                          <button
                            type="button"
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              borderRadius: "4px",
                              backgroundColor: "#000",
                              color: "#fff",
                              border: "none",
                              padding: "5px 20px",
                            }}
                            onClick={() =>
                              handleUpdateFaq(id, newFaqAnswer, newFaqQuestion)
                            }
                          >
                            Update
                          </button>
                          <button
                            type="button"
                            onClick={handleCancelClick3}
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              borderRadius: "4px",
                              backgroundColor: "lightgrey",
                              color: "#000",
                              border: "none",
                              padding: "5px 20px",
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  </div>
                )}

                {/* <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Workshop Slots:
                  </label>
                  <button
                    style={{
                      marginLeft: "10px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      backgroundColor: "#000",
                      color: "#fff",
                    }}
                    onClick={handleAddClick2}
                  >
                    + Add
                  </button>
                </div> */}
                {showFields2 && (
                  <>
                    <div
                      class="col-md-12"
                      style={{ display: "flex", paddingLeft: "0px" }}
                    >
                      <div
                        class="form-group col-md-6"
                        style={{ paddingLeft: "0px" }}
                      >
                        <label class="small mb-1" for="inputFullName">
                          Select Date<font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="date"
                          value={bookingDate}
                          min={bookingDate}
                          onChange={(e) => setBookingDate(e.target.value)}
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="small mb-1" for="inputFullName">
                          Select Start time <font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="time"
                          value={bookingStartTime}
                          onChange={(e) => setBookingStartTime(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      class="col-md-12"
                      style={{ display: "flex", paddingLeft: "0px" }}
                    >
                      <div
                        class="form-group col-md-6"
                        style={{ paddingLeft: "0px" }}
                      >
                        <label class="small mb-1" for="inputFullName">
                          Select End Time<font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="time"
                          value={bookingEndTime}
                          onChange={(e) => setBookingEndTime(e.target.value)}
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="small mb-1" for="inputFullName">
                          Enter Price <font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="number"
                          value={bookingPrice}
                          onChange={(e) => setBookingPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label class="small mb-1" for="inputFullName">
                        Enter Seating Capacity<font color="red"></font>
                      </label>
                      <input
                        class="form-control"
                        id="inputFullName"
                        type="number"
                        value={bookingCapacity}
                        onChange={(e) => setBookingCapacity(e.target.value)}
                      />
                    </div>
                    <div style={{ display: "flex", marginBottom: "20px" }}>
                      <button
                        type="button"
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          borderRadius: "4px",
                          backgroundColor: "#000",
                          color: "#fff",
                          border: "none",
                          padding: "5px 20px",
                        }}
                        onClick={handleAddBookingSlot}
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        onClick={handleCancelClick2}
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          borderRadius: "4px",
                          backgroundColor: "lightgrey",
                          color: "#000",
                          border: "none",
                          padding: "5px 20px",
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}

                {/* <div className="table-responsive">
                  {Array.isArray(bookingSlot) && bookingSlot.length > 0 ? (
                    <table
                      className="table table-striped"
                      style={{ padding: "0rem" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">Sr.No</th>
                          <th scope="col">Start Date</th>
                          <th scope="col">Start Time</th>
                          <th scope="col">End Time</th>
                          <th scope="col">Price</th>
                          <th scope="col">Seating Capacity</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingSlot.map((bookingSlot, index) => (
                          <tr key={bookingSlot.id}>
                            <th scope="row">{index + 1}</th>
                            <td>
                              <b>{bookingSlot.date || "---"}</b>
                            </td>
                            <td>
                              <b>{bookingSlot.startTime}</b>
                              <br />
                            </td>
                            <td>
                              <b>{bookingSlot.endTime}</b>
                              <br />
                            </td>
                            <td>
                              <b>{bookingSlot.price || "---"}</b>
                              <br />
                            </td>
                            <td>
                              <b>{bookingSlot.totalSeats || "---"}</b>
                              <br />
                            </td>
                            <td>
                              <div className="social-links mt-2">
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleClick4(bookingSlot.id)}
                                >
                                  <i className="bi bi-pencil"></i>
                                </a>
                                &nbsp;&nbsp;
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleDeleteWorkshop(bookingSlot.id)
                                  }
                                >
                                  <i className="bi bi-trash3"></i>
                                </a>
                                &nbsp;
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <h3 style={{ textAlign: "center" }}></h3>
                  )}
                </div> */}

                {showFields4 && (
                  <div>
                    <>
                      <div>
                        <div
                          class="col-md-12"
                          style={{ display: "flex", paddingLeft: "0px" }}
                        >
                          <div
                            class="form-group col-md-6"
                            style={{ paddingLeft: "0px" }}
                          >
                            <label class="small mb-1" for="inputFullName">
                              Select Date<font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="date"
                              // value={eachSlot.startDate}
                              value={bookingNewDate}
                              min={bookingDate}
                              onChange={(e) =>
                                setBookingNewDate(e.target.value)
                              }
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="small mb-1" for="inputFullName">
                              Select Start time <font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="time"
                              // value={eachSlot.startTime}
                              min={bookingDate}
                              value={bookingNewStartTime}
                              onChange={(e) =>
                                setBookingNewStartTime(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div
                          class="col-md-12"
                          style={{ display: "flex", paddingLeft: "0px" }}
                        >
                          <div
                            class="form-group col-md-6"
                            style={{ paddingLeft: "0px" }}
                          >
                            <label class="small mb-1" for="inputFullName">
                              Select End Time<font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="time"
                              // value={eachSlot.endTime}
                              value={bookingNewEndTime}
                              onChange={(e) =>
                                setBookingNewEndTime(e.target.value)
                              }
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="small mb-1" for="inputFullName">
                              Enter Price <font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="text"
                              // value={eachSlot.price}
                              value={bookingNewPrice}
                              onChange={(e) =>
                                setBookingNewPrice(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <label class="small mb-1" for="inputFullName">
                            Enter Seating Capacity<font color="red"></font>
                          </label>
                          <input
                            class="form-control"
                            id="inputFullName"
                            type="text"
                            // value={eachSlot.capacity}
                            value={bookingNewCapacity}
                            onChange={(e) =>
                              setBookingNewCapacity(e.target.value)
                            }
                          />
                        </div>
                        <div style={{ display: "flex", marginBottom: "20px" }}>
                          <button
                            type="button"
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              borderRadius: "4px",
                              backgroundColor: "#000",
                              color: "#fff",
                              border: "none",
                              padding: "5px 20px",
                            }}
                            // onClick={handleeUpdate}
                            onClick={() =>
                              handleUpdateWorkshop(
                                id2,
                                bookingNewDate,
                                bookingNewStartTime,
                                bookingNewEndTime,
                                bookingNewPrice,
                                bookingNewCapacity
                              )
                            }
                          >
                            Update
                          </button>
                          <button
                            type="button"
                            onClick={handleCancelClick4}
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              borderRadius: "4px",
                              backgroundColor: "lightgrey",
                              color: "#000",
                              border: "none",
                              padding: "5px 20px",
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  </div>
                )}

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status
                  </label>
                  <div class="child ml-2">
                    <Select
                      value={newWorkshopStatus}
                      onChange={handleStatusChange}
                      options={status}
                    ></Select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="small mb-1" htmlFor="inputFullName">
                    Select Instructor<font color="red">*</font>
                  </label>
                  <Select
                    options={instructors}
                    // options={filteredOptions}
                    onInputChange={handleInputChange}
                    value={selectedInstructor} // value prop for controlled component
                    onChange={handleInstructorChange} // handle change function
                    getOptionLabel={(option) => (
                      <div>
                        <img
                          src={option.image}
                          style={{ width: "30px", marginRight: "10px" }}
                        />
                        {option.label}
                      </div>
                    )}
                    getOptionValue={(option) => option.value}
                    isSearchable
                  />
                </div>
                <div class="form-group">
                    <label class="small mb-1" for="inputFullName">
                      Online
                    </label>
                    <div class="child ml-2">
                      <Select
                        value={newWorkshopOnline}
                        onChange={handleOnlineChange}
                        options={online}
                      ></Select>
                    </div>
                    </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>

              <button
                id="btnAdd"
                type="button"
                class="btn btn-primary "
                style={{ background: "#000 ", border: "none" }}
                onClick={handleUpdate}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_workshop;
