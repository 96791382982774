import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./Style.css";
import "../../Api/apiUrls";
import {
  API_URL_NEW,
  logOut,
  loginCheck,
  Update_MarkClicked,
} from "../../Api/apiUrls";
import useWebSocketService from "../../websocket_management/WebSocketServer";
import NotiTune from "../../Static/tone/announcement_sound.mp3";
import NotivibeIcon from "../../Static/img/A_LOGO.jpeg";

function Header() {
  // var is_login = localStorage.getItem("is_login");
  // if (is_login == null || is_login == false) {
  //   window.location.href = "http://3.110.1.182/";
  // }
  // console.log(is_login);

  function init() {
    const urlParams = new URLSearchParams(window.location.search);

    // if (localStorage.getItem('user_id') === null || localStorage.getItem('user_id') === "") {
    //     localStorage.clear();
    //     window.location.href = `${API_URL_NEW}`;
    // } else
    if (urlParams.has("signOut")) {
      localStorage.clear();
      window.location.href = `${API_URL_NEW}`;
    }
  }

  const user_id = localStorage.getItem("user_id");

  const { getNotiiiii, disconnect, setOnMessageHandler, onmessage } =
    useWebSocketService(user_id);

  useEffect(() => {
    setOnMessageHandler((message) => {
      // Handle incoming messages here
      Notification.requestPermission();

      if (!Array.isArray(message.data)) {
        showNotification(message.notification_message);
      }
      const newData = Array.isArray(message.data) ? message.data : [message];

      console.log(newData);

      // Update state using a callback to avoid issues with asynchronous state updates
      setNotiList((prevNotifications) => [...newData, ...prevNotifications]);
    });

    // Disconnect on component unmount
    return () => {
      disconnect();
    };
  }, [user_id]);

  const showNotification = (notification_message) => {
    if (Notification.permission === "granted") {
      const notification = new Notification("Artiquity", {
        body: notification_message,
        vibrate: [200, 100, 200],
        icon: NotivibeIcon,
        sound: NotiTune,
        dir: "auto",
      });

      // You can handle click events or other events here
      notification.onclick = () => {
        console.log("Notification clicked.");
      };

      // Position the notification in the bottom right corner
    } else {
      Notification.requestPermission();
      console.error("Notification permission denied.");
    }
  };

  const [NotiList, setNotiList] = useState([]);
  const [visibleNotifications, setVisibleNotifications] = useState(10);
  const [isShowMoreClicked, setIsShowMoreClicked] = useState(false);

  const UpdateMarkClicked = async () => {
    const user_id = localStorage.getItem("user_id");
    const url = `${Update_MarkClicked}?user_id=${user_id}`;

    try {
      const response = await fetch(url, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Unable to update");
      }

      const data = await response.json();
      console.log(data);
      console.log("Success");
    } catch (error) {
      console.error("Error updating notification:", error);
    }
  };

  const formatNotificationDateTime = (created_at) => {
    const date = new Date(created_at);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const handleNotificationClick = (notification) => {
    console.log(notification);
    console.log(notification.id);
    if (!notification.adminIsRead) {
      // Update the notification object
      const updatedNotification = { ...notification, adminIsRead: true };
      // Find the index of the clicked notification in the current notification list
      const notificationIndex = NotiList.findIndex(
        (notif) => notif.id === updatedNotification.id
      );
      // Create a new array with the updated notification
      const updatedNotiList = [...NotiList];
      updatedNotiList[notificationIndex] = updatedNotification;
      // Update the state with the new array
      setNotiList(updatedNotiList);
      // Call the function to update the is_seen status in the database
      // UpdateBoardTemplate(notification.id);
    }
  };

  const handleShowMore = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setVisibleNotifications(NotiList.length);
    setIsShowMoreClicked(true);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const dropdownStyles = {
    maxHeight: "450px",
    maxWidth: "400px",
    minWidth: "350px",
    overflowY: "auto",
    scrollbarWidth: "thin", // For Firefox
    scrollbarColor: "#888 #ddd", // For Firefox

    "&::-webkit-scrollbar": {
      width: "2px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888", // For WebKit browsers
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#aaa", // For WebKit browsers
    },
    // position: 'absolute',
    // left: '50%',
    transform: windowWidth <= 768 ? "translateX(-80%)" : "",

    // borderRadius:4,boxShadow: '2px 2px #5555552b'
  };

  const countNotClickedNotifications = () => {
    return NotiList.filter((notification) => !notification.adminIsRead).length;
  };

  console.log(NotiList);

  const [token, setToken] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    setToken(token);

    const tokenNew = localStorage.getItem("token");
    if (tokenNew === token) {
      console.log("Found in the session storage");
    } else {
      fetch(`${loginCheck}?token=${token}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            localStorage.setItem("token", token);
            localStorage.setItem("user_id", data.data.id);
          } else {
            console.log("Not there in database");
            window.location.href = API_URL_NEW;
            // window.location.href = "http://127.0.0.1:5506/index.html";
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []);

  init();

  const [isIntegrateMenuOpen, setIntegrateMenuOpen] = useState(false);
  const [isIntegrateMenuOpen2, setIntegrateMenuOpen2] = useState(false);
  const currentURL = window.location.href;
  console.log(currentURL);

  const customerId = localStorage.getItem("user_id");
  const handleSignOut = async () => {
    try {
      const response = await fetch(logOut + customerId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        localStorage.clear();
        window.location.href = `${API_URL_NEW}?signOut`;
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const [addClass, setAddClass] = useState(false);

  useEffect(() => {
    if (addClass) {
      document.body.classList.add("toggle-sidebar");
    } else {
      document.body.classList.remove("toggle-sidebar");
    }
  }, [addClass]);

  function redirectToProfile() {
    window.location.href = `/admin/profile`;
  }

  return (
    <>
      <header
        id="header"
        class="header fixed-top d-flex align-items-center"
        style={{ borderBottom: "solid 1px rgba(0, 0, 0, 0.329)" }}
      >
        <div class="d-flex align-items-center justify-content-between">
          <a
            href="https://artiquity.in/"
            class="logo d-flex align-items-center"
          >
            <img src={require("../../Static/img/ARTIQUITY_LOGO.jpeg")} />
            <span class="d-none d-lg-block"></span>
          </a>

          <a onClick={() => setAddClass(!addClass)}>
            <i class="bi bi-list toggle-sidebar-btn"></i>
          </a>
        </div>

        <nav class="header-nav ms-auto">
          <ul class="d-flex align-items-center">
            <li class="nav-item dropdown pe-3">
              <a
                class="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                data-bs-toggle="dropdown"
                onClick={() => UpdateMarkClicked()}
              >
                <div className="mr-3">
                  <i
                    className="bi bi-bell"
                    style={{
                      fontSize: "20px",
                      position: "relative",
                      cursor: "pointer",
                    }}
                  >
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">
                      {countNotClickedNotifications()}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </i>
                </div>
              </a>

              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-right pb-2"
                style={dropdownStyles}
                onClick={(e) => e.preventDefault()}
              >
                <span className="dropdown-item dropdown-header">
                  {countNotClickedNotifications()} Notifications
                </span>
                <div className="dropdown-divider"></div>
                {NotiList.slice(0, visibleNotifications).map(
                  (notification, index) => (
                    <div
                      key={index}
                      style={{
                        borderRadius: 0,
                        boxShadow: "1px 1px #5555552b",
                      }}
                    >
                      <a
                        href="#"
                        className={`dropdown-item ${
                          notification.adminIsRead ? "text-muted" : ""
                        }`}
                        onClick={() => handleNotificationClick(notification)}
                        style={{
                          backgroundColor: "white",
                          textWrap: "wrap",
                          padding: "9px",
                          color: "black",
                        }}
                      >
                        {notification.notification_message}
                      </a>
                      <span
                        className="float-right text-sm"
                        style={{ fontSize: "smaller", paddingRight: "9px" }}
                      >
                        {formatNotificationDateTime(notification.created_at)}
                      </span>
                      <hr style={{ margin: "1.6rem 0" }} />
                    </div>
                  )
                )}
                {NotiList.length > visibleNotifications && (
                  <div className="dropdown-item text-center">
                    <p
                      className={`dropdown-footer ${
                        isShowMoreClicked ? "clicked" : ""
                      }`}
                      onClick={handleShowMore}
                      style={{ cursor: "pointer" }}
                    >
                      <b style={{ fontSize: 16 }}>
                        {isShowMoreClicked ? "Show Less" : "Show More"}
                      </b>
                    </p>
                  </div>
                )}
                {/* <div className="dropdown-divider"></div> */}
              </div>
            </li>

            <li class="nav-item dropdown pe-3">
              <a
                class="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                data-bs-toggle="dropdown"
              >
                <img
                  src={require("../../Static/img/messages-2.jpg")}
                  alt="Profile"
                  class="rounded-circle"
                  style={{ aspectRatio: "1/1", borderRadius: "7px" }}
                />
                <span class="d-none d-md-block dropdown-toggle ps-2">
                  Admin
                </span>
              </a>
              <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li class="dropdown-header">
                  <h6>Admin</h6>
                  <span>Role: Admin</span>
                </li>

                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li>
                  <Link
                    class="dropdown-item d-flex align-items-center"
                    to={{
                      pathname: "/admin/profile",
                      search: `?token=${token}`,
                    }}
                  >
                    <i class="bi bi-person-circle"></i>
                    <span>View Profile</span>
                  </Link>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="#"
                    onClick={handleSignOut}
                  >
                    <i class="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>

      <aside id="sidebar" class="sidebar">
        <ul class="sidebar-nav" id="sidebar-nav">
          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/dashboard",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/dashboard?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-columns-gap"></i>
              <span style={{ marginLeft: "3%" }}> Dashboard</span>
            </Link>
          </li>

          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/workshop",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/workshop?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-shop-window"></i>
              {/* <i class="bi bi-list"></i> */}
              <span style={{ marginLeft: "3%" }}>Workshop</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/venue",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/venue?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-geo-alt"></i>
              {/* <i class="bi bi-list"></i> */}
              <span style={{ marginLeft: "3%" }}>Venue</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/news",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/news?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-newspaper"></i>
              <span style={{ marginLeft: "3%" }}>News</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/blog",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/blog?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-stars"></i>
              <span style={{ marginLeft: "3%" }}>Blog</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/pages",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/pages?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-file-earmark"></i>
              {/* <i class="bi bi-list"></i> */}
              <span style={{ marginLeft: "3%" }}>Pages</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/categories",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/categories?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i className="bi bi-grid"></i>
              <span style={{ marginLeft: "3%" }}> Category</span>
            </Link>
          </li>

          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/sub_categories",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/sub_categories?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-calendar2-event"></i>
              <span style={{ marginLeft: "3%" }}>Sub category</span>
            </Link>
          </li>

          <li
            class="nav-item "
            style={{ cursor: "pointer" }}
            onClick={() => setIntegrateMenuOpen(!isIntegrateMenuOpen)}
          >
            <a class="nav-link collapsed">
              {" "}
              <i class="bi bi-person"></i>
              <span style={{ marginLeft: "9px" }}> User</span>
              <span
                style={{
                  // float: "right",
                  cursor: "pointer",
                  marginLeft: "53%",
                  fontSize: "13px",
                }}
              >
                {isIntegrateMenuOpen ? (
                  <i class="bi bi-chevron-down"></i>
                ) : (
                  <i class="bi bi-chevron-right"></i>
                )}
              </span>
            </a>
          </li>
          {isIntegrateMenuOpen && (
            <>
              <li class="nav-item" style={{ marginLeft: "20px" }}>
                <Link
                  to={{
                    pathname: "/admin/instructor",
                    search: `?token=${token}`,
                  }}
                  className={
                    "nav-link" +
                    (currentURL ===
                    `http://localhost:3000/admin/instructor?token=${token}`
                      ? ""
                      : " collapsed")
                  }
                >
                  <i class="bi bi-circle" style={{ fontSize: "10px" }}></i>
                  {/* <i class="bi bi-list"></i> */}
                  <span style={{ marginLeft: "3%", fontSize: "13px" }}>
                    Instructor
                  </span>
                </Link>
              </li>
              <li class="nav-item" style={{ marginLeft: "20px" }}>
                <Link
                  to={{
                    pathname: "/admin/venue_owner",
                    search: `?token=${token}`,
                  }}
                  className={
                    "nav-link" +
                    (currentURL ===
                    `http://localhost:3000/admin/venue_owner?token=${token}`
                      ? ""
                      : " collapsed")
                  }
                >
                  <i class="bi bi-circle" style={{ fontSize: "10px" }}></i>
                  {/* <i class="bi bi-list"></i> */}
                  <span style={{ marginLeft: "3%", fontSize: "13px" }}>
                    Venue Provider
                  </span>
                </Link>
              </li>
              <li class="nav-item" style={{ marginLeft: "20px" }}>
                <Link
                  to={{
                    pathname: "/admin/customer",
                    search: `?token=${token}`,
                  }}
                  className={
                    "nav-link" +
                    (currentURL ===
                    `http://localhost:3000/admin/customer?token=${token}`
                      ? ""
                      : " collapsed")
                  }
                >
                  <i class="bi bi-circle" style={{ fontSize: "10px" }}></i>
                  {/* <i class="bi bi-list"></i> */}
                  <span style={{ marginLeft: "3%", fontSize: "13px" }}>
                    Customer
                  </span>
                </Link>
              </li>
            </>
          )}
          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/review",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/review?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-stars"></i>
              <span style={{ marginLeft: "3%" }}>Review</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/location",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/location?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-geo"></i>
              <span style={{ marginLeft: "3%" }}>Location</span>
            </Link>
            <li class="nav-item">
              <Link
                to={{
                  pathname: "/admin/packages",
                  search: `?token=${token}`,
                }}
                className={
                  "nav-link" +
                  (currentURL === `http://localhost:3000/admin/packages`
                    ? ""
                    : " collapsed")
                }
              >
                <i class="bi bi-cash-stack"></i>
                {/* <i class="bi bi-list"></i> */}
                <span style={{ marginLeft: "3%" }}>Package</span>
              </Link>
            </li>
          </li>
          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/packageManagement",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/packageManagement?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-receipt"></i>
              {/* <i class="bi bi-list"></i> */}
              <span style={{ marginLeft: "3%" }}>Package Management</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/transaction",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/transaction?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-wallet2"></i>
              <span style={{ marginLeft: "3%" }}>Transaction</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/appointment",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/appointment?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-calendar2-event"></i>
              <span style={{ marginLeft: "3%" }}>Appointment</span>
            </Link>
          </li>

          <li
            class="nav-item"
            style={{ cursor: "pointer" }}
            onClick={() => setIntegrateMenuOpen2(!isIntegrateMenuOpen2)}
          >
            <a class="nav-link collapsed">
              {" "}
              <i class="bi bi-file-earmark"></i>
              <span style={{ marginLeft: "9px" }}> Home Setting</span>
              <span
                style={{
                  // float: "right",
                  cursor: "pointer",
                  marginLeft: "25%",
                  fontSize: "13px",
                }}
              >
                {isIntegrateMenuOpen2 ? (
                  <i class="bi bi-chevron-down"></i>
                ) : (
                  <i class="bi bi-chevron-right"></i>
                )}
              </span>
            </a>
          </li>
          {isIntegrateMenuOpen2 && (
            <>
              <li class="nav-item" style={{ marginLeft: "20px" }}>
                <Link
                  to={{
                    pathname: "/admin/Banner",
                    search: `?token=${token}`,
                  }}
                  className={
                    "nav-link" +
                    (currentURL ===
                    `http://localhost:3000/admin/banner?token=${token}`
                      ? ""
                      : " collapsed")
                  }
                >
                  <i class="bi bi-circle" style={{ fontSize: "10px" }}></i>
                  {/* <i class="bi bi-list"></i> */}
                  <span style={{ marginLeft: "3%", fontSize: "13px" }}>
                    Banner
                  </span>
                </Link>
              </li>
            </>
          )}
          {isIntegrateMenuOpen2 && (
            <>
              <li class="nav-item" style={{ marginLeft: "20px" }}>
                <Link
                  to={{
                    pathname: "/admin/review_and_rating",
                    search: `?token=${token}`,
                  }}
                  className={
                    "nav-link" +
                    (currentURL ===
                    `http://localhost:3000/admin/review_and_rating?token=${token}`
                      ? ""
                      : " collapsed")
                  }
                >
                  <i class="bi bi-circle" style={{ fontSize: "10px" }}></i>
                  {/* <i class="bi bi-list"></i> */}
                  <span style={{ marginLeft: "3%", fontSize: "13px" }}>
                    Review Rating
                  </span>
                </Link>
              </li>
            </>
          )}
          {isIntegrateMenuOpen2 && (
            <>
              <li class="nav-item" style={{ marginLeft: "20px" }}>
                <Link
                  to={{
                    pathname: "/admin/aboutUs",
                    search: `?token=${token}`,
                  }}
                  className={
                    "nav-link" +
                    (currentURL ===
                    `http://localhost:3000/admin/aboutUs?token=${token}`
                      ? ""
                      : " collapsed")
                  }
                >
                  <i class="bi bi-circle" style={{ fontSize: "10px" }}></i>
                  {/* <i class="bi bi-list"></i> */}
                  <span style={{ marginLeft: "3%", fontSize: "13px" }}>
                    About Us
                  </span>
                </Link>
              </li>
            </>
          )}
          {isIntegrateMenuOpen2 && (
            <>
              <li class="nav-item" style={{ marginLeft: "20px" }}>
                <Link
                  to={{
                    pathname: "/admin/subscribersList",
                    search: `?token=${token}`,
                  }}
                  className={
                    "nav-link" +
                    (currentURL ===
                    `http://localhost:3000/admin/subscribersList?token=${token}`
                      ? ""
                      : " collapsed")
                  }
                >
                  <i class="bi bi-circle" style={{ fontSize: "10px" }}></i>
                  {/* <i class="bi bi-list"></i> */}
                  <span style={{ marginLeft: "3%", fontSize: "13px" }}>
                    Subscribers List
                  </span>
                </Link>
              </li>
            </>
          )}
          {isIntegrateMenuOpen2 && (
            <>
              <li class="nav-item" style={{ marginLeft: "20px" }}>
                <Link
                  to={{
                    pathname: "/admin/happyCustomer",
                    search: `?token=${token}`,
                  }}
                  className={
                    "nav-link" +
                    (currentURL ===
                    `http://localhost:3000/admin/happyCustomer?token=${token}`
                      ? ""
                      : " collapsed")
                  }
                >
                  <i class="bi bi-circle" style={{ fontSize: "10px" }}></i>
                  {/* <i class="bi bi-list"></i> */}
                  <span style={{ marginLeft: "3%", fontSize: "13px" }}>
                    Happy Customer
                  </span>
                </Link>
              </li>
            </>
          )}
          {isIntegrateMenuOpen2 && (
            <>
              <li class="nav-item" style={{ marginLeft: "20px" }}>
                <Link
                  to={{
                    pathname: "/admin/Faq",
                    search: `?token=${token}`,
                  }}
                  className={
                    "nav-link" +
                    (currentURL ===
                    `http://localhost:3000/admin/faq?token=${token}`
                      ? ""
                      : " collapsed")
                  }
                >
                  <i class="bi bi-circle" style={{ fontSize: "10px" }}></i>
                  {/* <i class="bi bi-list"></i> */}
                  <span style={{ marginLeft: "3%", fontSize: "13px" }}>
                    FAQ
                  </span>
                </Link>
              </li>
            </>
          )}
          {isIntegrateMenuOpen2 && (
            <>
              <li class="nav-item" style={{ marginLeft: "20px" }}>
                <Link
                  to={{
                    pathname: "/admin/cancellationPolicy",
                    search: `?token=${token}`,
                  }}
                  className={
                    "nav-link" +
                    (currentURL ===
                    `http://localhost:3000/admin/cancellationPolicy?token=${token}`
                      ? ""
                      : " collapsed")
                  }
                >
                  <i class="bi bi-circle" style={{ fontSize: "10px" }}></i>
                  {/* <i class="bi bi-list"></i> */}
                  <span style={{ marginLeft: "3%", fontSize: "13px" }}>
                    Cancellation Policy
                  </span>
                </Link>
              </li>
            </>
          )}
          {isIntegrateMenuOpen2 && (
            <>
              <li class="nav-item" style={{ marginLeft: "20px" }}>
                <Link
                  to={{
                    pathname: "/admin/privacyPolicy",
                    search: `?token=${token}`,
                  }}
                  className={
                    "nav-link" +
                    (currentURL ===
                    `http://localhost:3000/admin/privacyPolicy?token=${token}`
                      ? ""
                      : " collapsed")
                  }
                >
                  <i class="bi bi-circle" style={{ fontSize: "10px" }}></i>
                  {/* <i class="bi bi-list"></i> */}
                  <span style={{ marginLeft: "3%", fontSize: "13px" }}>
                    Privacy Policy
                  </span>
                </Link>
              </li>
            </>
          )}
          {isIntegrateMenuOpen2 && (
            <>
              <li class="nav-item" style={{ marginLeft: "20px" }}>
                <Link
                  to={{
                    pathname: "/admin/terms&conditions",
                    search: `?token=${token}`,
                  }}
                  className={
                    "nav-link" +
                    (currentURL ===
                    `http://localhost:3000/admin/terms&conditions?token=${token}`
                      ? ""
                      : " collapsed")
                  }
                >
                  <i class="bi bi-circle" style={{ fontSize: "10px" }}></i>
                  {/* <i class="bi bi-list"></i> */}
                  <span style={{ marginLeft: "3%", fontSize: "13px" }}>
                    Terms & Conditions
                  </span>
                </Link>
              </li>
            </>
          )}
          {isIntegrateMenuOpen2 && (
            <>
              <li class="nav-item" style={{ marginLeft: "20px" }}>
                <Link
                  to={{
                    pathname: "/admin/weAreHiring",
                    search: `?token=${token}`,
                  }}
                  className={
                    "nav-link" +
                    (currentURL ===
                    `http://localhost:3000/admin/weAreHiring?token=${token}`
                      ? ""
                      : " collapsed")
                  }
                >
                  <i class="bi bi-circle" style={{ fontSize: "10px" }}></i>
                  {/* <i class="bi bi-list"></i> */}
                  <span style={{ marginLeft: "3%", fontSize: "13px" }}>
                    We Are Hiring
                  </span>
                </Link>
              </li>
            </>
          )}
          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/questions",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/questions?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-question-circle"></i>
              <span style={{ marginLeft: "3%" }}>Question</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/messages",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/messages?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-chat-dots"></i>
              <span style={{ marginLeft: "3%" }}>Messages</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/instructorPayOut",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/instructorPayOut?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-wallet"></i>
              <span style={{ marginLeft: "3%" }}>Instructor PayOut</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link
              to={{
                pathname: "/admin/customerPayOut",
                search: `?token=${token}`,
              }}
              className={
                "nav-link" +
                (currentURL ===
                `http://localhost:3000/admin/customerPayOut?token=${token}`
                  ? ""
                  : " collapsed")
              }
            >
              <i class="bi bi-wallet"></i>
              <span style={{ marginLeft: "3%" }}>Customer PayOut</span>
            </Link>
          </li>
        </ul>
      </aside>
    </>
  );
}

export default Header;
