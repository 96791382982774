import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_instructor.css";
import Select from "react-select";
import { API_URL, updateUser } from "../../Api/apiUrls";

function Update_instructor({ onClose, instructor }) {
    console.log(instructor);
    const [modalUpdate_instructorIsOpen, setUpdate_instructorModalIsOpen] =
        useState("");
    const [updatedInstructor, setUpdatedInstructor] = React.useState(instructor);

    const [fullName, setFullName] = useState("");
    const [bio, setBio] = useState("");
    const [mobileNo, setMobile] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [bankname, setBankName] = useState("");
    const [account, setAccount] = useState("");
    const [code, setCode] = useState("");
    const [pancard, setPanCard] = useState("");
    const [country, setCountry] = useState("");
    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");
    const [twitter, setTwitter] = useState("");
    const [city, setCity] = useState("");
    const [youtube, setYoutube] = useState("");
    const [instructorId, setInstructorId] = useState("");
    const [file, setIconFile] = useState();
    const [aadharcard, setAadharcard] = useState("");
    const [gstno, setGstno] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [newStatus, setNewStatus] = useState("");


    const closeUpdate_instructor = () => {
        setUpdate_instructorModalIsOpen(false);
    };

    function reloadPage() {
        window.location.reload();
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        setIconFile(file);
        console.log(file);

        const formData = new FormData();
        formData.append("profile_image", file);
        const reader = new FileReader();
        reader.onload = function (e) {
            const imgElement = document.getElementById("selectedImg");
            imgElement.src = e.target.result;
        };
        reader.readAsDataURL(file);
    };

    const [isAddinginstructor, setIsAddinginstructor] = useState(false);
    const status = [
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" },
      ];

      const handleStatusChange = (selectedOption) => {
        setNewStatus(selectedOption);
      };

    React.useEffect(() => {
        setUpdatedInstructor(instructor);
        setFullName(updatedInstructor?.name || "");
        setBio(updatedInstructor?.bio || "");
        setMobile(updatedInstructor?.mobileNumber || "");
        setAddress(updatedInstructor?.address || "");
        setEmail(updatedInstructor?.email || "");
        setBankName(updatedInstructor?.bank_name || "");
        setAccount(updatedInstructor?.bank_account_number || "");
        setCode(updatedInstructor?.bank_ifsc_code || "");
        setPanCard(updatedInstructor?.pan_card_number || "");
        setCountry(updatedInstructor?.country || "");
        setFacebook(updatedInstructor?.facebook || "");
        setInstagram(updatedInstructor?.instagram || "");
        setTwitter(updatedInstructor?.twitter || "");
        setCity(updatedInstructor?.city || "");
        setYoutube(updatedInstructor?.youtube || "");
        setIconFile(updatedInstructor?.profile_image || "");
        setAadharcard(updatedInstructor?.aadhar_card_number || "");
        setGstno(updatedInstructor?.gst_number || "");
        setBusinessName(updatedInstructor?.business_name || "");
        setNewStatus(status.find(option => option.value === updatedInstructor?.status) || "");
        setInstructorId(updatedInstructor?.id || "");
    }, [instructor]);

   console.log(newStatus);
    const handleSaveClick = async () => {
        try {
            const formData = new FormData();
            formData.append("user_id", instructorId);
            formData.append("name", fullName);
            formData.append("profile_image", file);
            formData.append("bio", bio);
            formData.append("mobileNumber", mobileNo);
            formData.append("email", email);
            formData.append("address", address);
            formData.append("city", city);
            formData.append("country", country);
            formData.append("facebook", facebook);
            formData.append("instagram", instagram);
            formData.append("youtube", youtube);
            formData.append("twitter", twitter);
            formData.append("bank_name", bankname);
            formData.append("bank_account_number", account);
            formData.append("bank_ifsc_code", code);
            formData.append("pan_card_number", pancard);
            formData.append("aadhar_card_number", aadharcard);
            formData.append("gst_number", gstno);
            formData.append("business_name", businessName);
            formData.append("status", newStatus ? newStatus.label : instructor.status);
            const response = await fetch(`${updateUser}`, {
                method: "PUT",
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`Failed to save data: ${response.statusText}`);
            }

            alert("Details updated successfully");
            reloadPage();


        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    return (
        <div>
            {modalUpdate_instructorIsOpen && (
                <Update_instructor onClose={closeUpdate_instructor} />
            )}
            <div className="popup-background">
                <div className="popup">
                    <div
                        class="modal-content"
                        style={{
                            width: "100%",
                            height: "80%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                        }}
                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Update Details</b>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            class="modal-body"
                            style={{ overflowY: "scroll", padding: "2%" }}
                        >
                            <form>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Full name<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text"
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputDocument">
                                        Cover Image
                                    </label>
                                    <br />
                                    <input
                                        type="file"
                                        id="selectedImg"
                                        name="document"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />
                                    <img
                                        id="selectedImg"
                                        src={`${API_URL}/${file}`}
                                        // alt={category.name || 'No Name'}
                                        style={{ width: "60px", height: "60px" }}
                                    />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Bio<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text"
                                        value={bio}
                                        onChange={(e) => setBio(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Mobile Number<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text"
                                        value={mobileNo}
                                        onChange={(e) => setMobile(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Email<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Address<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        City<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Country<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text"
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Bank Name<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text"
                                        value={bankname}
                                        onChange={(e) => setBankName(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Account Number<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text"
                                        value={account}
                                        onChange={(e) => setAccount(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        IFSC code<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        PAN card No.<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text" value={pancard}
                                        onChange={(e) => setPanCard(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Aadhar Card No.<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text" value={aadharcard}
                                        onChange={(e) => setAadharcard(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        GST No.<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text" value={gstno}
                                        onChange={(e) => setGstno(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Business Name<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text" value={businessName}
                                        onChange={(e) => setBusinessName(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Facebook<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text"
                                        value={facebook} onChange={(e) => setFacebook(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Instagram<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text"
                                        value={instagram}
                                        onChange={(e) => setInstagram(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        You Tube<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text"
                                        value={youtube}
                                        onChange={(e) => setYoutube(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Twitter<font color="red"></font>
                                    </label>
                                    <input class="form-control" id="inputFullName" type="text"
                                        value={twitter}
                                        onChange={(e) => setTwitter(e.target.value)} />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Status
                                    </label>
                                    <div class="child ml-2">
                                        <Select
                                             value={status.find(option => option.value === newStatus.label)}
                                            onChange={handleStatusChange}
                                            options={status}
                                        ></Select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div
                            class="modal-footer"
                            style={{
                                padding: "2%",
                                marginTop: "3%",
                                borderTop: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                    onClose();
                                    //   reloadPage();
                                }}
                            >
                                Close
                            </button>
                            {isAddinginstructor === true ? (
                                <div>
                                    <ThreeDots color="#000" height={50} width={50} />
                                </div>
                            ) : (
                                <button
                                    id="btnAdd"
                                    type="button"
                                    class="btn btn-primary "
                                    style={{ background: "#000 ", border: "none" }}
                                    onClick={handleSaveClick}
                                >
                                    Update
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Update_instructor;
