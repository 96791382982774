import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import ReactSwitch from "react-switch";
import { TailSpin } from "react-loader-spinner";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_workshop from "../../Components/Admin_components/add_workshop.js";
import Update_workshop from "../../Components/Admin_components/update_workshop.js";
import Delete_workshop from "../../Components/Admin_components/delete_workshop.js";
import Workshop_booking_slots from "../../Components/Admin_components/Workshop_booking_slots.js";
import WorkshopReview from "../../Components/Admin_components/workshopReview.js";
import WorkshopWishlist from "../../Components/Admin_components/workshopWishlist.js";
import WorkshopTotalAppointment from "../../Components/Admin_components/workshopTotalAppointment.js";
import WorkshopActiveAppointment from "../../Components/Admin_components/workshopActiveAppointment.js";
import Add_mediaa from "../../Components/Admin_components/workshop_addMedia.js";
import AddWorkshopbyExcel from "../../Components/Admin_components/addWorkshopbyExcel.js";
import "../../Api/apiUrls";
import {
  getAdminWorkshop,
  workshopExcel,
  UpdateAdminWorkshopToggle,
} from "../../Api/apiUrls";
function Workshop() {
  const [id, setId] = useState("");
  const [toggleStates, setToggleStates] = useState({});
  const [toggleStates2, setToggleStates2] = useState({});
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const [workshopData, setWorkshopData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [filteredWorkshopData, setFilteredWorkshopData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // useEffect to filter workshop data when filter options change
  useEffect(() => {
    const filteredData = workshopData.filter((workshop) => {
      // const isFilter =
      //   !selectedFilter ||
      //   selectedFilter.value === "All" ||
      //   workshop.filter === selectedFilter.value;
      const isStatus =
        !selectedStatus ||
        selectedStatus.value === "All" ||
        workshop.status === selectedStatus.value;
      const isMonth =
        !selectedMonth ||
        selectedMonth.value === "All" ||
        workshop.created_at_month === selectedMonth.value;
      const isYear =
        !selectedYear ||
        selectedYear.value === "All" ||
        workshop.created_at_year === selectedYear.value;

              // Safely access the venue_owner's name with optional chaining
      const instructorName = workshop.workshopInstructor?.name?.toLowerCase() || "";
      
      const values = [
        ...Object.values(workshop).map((value) =>
          typeof value === "string" ? value.toLowerCase() : ""
        ),
        instructorName,
      ];

      // const values = Object.values(workshop).map((value) =>
      //   typeof value === "string" ? value.toLowerCase() : ""
      // );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );
      return isStatus && isMonth && isYear && isSearchMatch;
    });

    setFilteredWorkshopData(filteredData);
  }, [

    selectedStatus,
    selectedYear,
    selectedMonth,
    workshopData,
    searchQuery,
  ]);

  const [modalWorkshop_booking_slotsIsOpen, setWorkshop_booking_slotsIsOpen] =
    useState("");
  const [modalAdd_workshopIsOpen, setAdd_workshopModalIsOpen] = useState("");
  const [modalUpdate_workshopIsOpen, setUpdate_workshopModalIsOpen] =
    useState(false);
  const [modalDelete_workshopIsOpen, setDelete_workshopIsOpen] =
    useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [dateTime, setDateTime] = useState("");
  const [modalWorkshopReviewIsOpen, setWorkshopReviewModalIsOpen] =
    useState("");
  const [modalAdd_mediaIsOpen, setAdd_MediaModalIsOpen] = useState("");

  const [workshopSelectedName, setSelectedWorkshopName] = useState(null);
  const [
    workshopSelectedShortDescription,
    setSelectedWorkshopShortDescription,
  ] = useState(null);
  const [workshopSelectedDescription, setSelectedWorkshopDescription] =
    useState(null);
  const [workshopSelectedKnowledge, setSelectedWorkshopKnowledge] =
    useState(null);
  const [workshopSelectedWhatYouGet, setSelectedWorkshopWhatYouGet] =
    useState(null);
  const [workshopSelectedWhatBring, setSelectedWorkshopWhatBring] =
    useState(null);
  const [workshopSelectedSuitable, setSelectedWorkshopSuitable] =
    useState(null);
  const [workshopSelectedSeats, setSelectedWorkshopSeats] = useState(null);
  const [workshopSelectedDuration, setSelectedWorkshopDuration] =
    useState(null);
  const [workshopSelectedPrice, setSelectedWorkshopPrice] = useState(null);
  const [workshopSelectedCategory, setSelectedWorkshopCategory] =
    useState(null);
  const [workshopSelectedCity, setSelectedWorkshopCity] = useState(null);
  const [workshopSelectedLocation, setSelectedWorkshopLocation] =
    useState(null);
  const [workshopSelectedSubCategory, setSelectedWorkshopSubCategory] =
    useState(null);

  const [workshopSelectedAddress, setSelectedWorkshopAddress] = useState(null);
  const [workshopSelectedPostalCode, setSelectedWorkshopPostalCode] =
    useState(null);
  const [workshopSelectedGoogleCode, setSelectedWorkshopGoogleCode] =
    useState(null);
  const [workshopSelectedEmail, setSelectedWorkshopEmail] = useState(null);
  const [workshopSelectedPhone, setSelectedWorkshopPhone] = useState(null);
  const [workshopSelectedWebsite, setSelectedWorkshopWebsite] = useState(null);
  const [workshopSelectedStatus, setWorkshopSelectedStatus] = useState(null);
  const [workshopSelectedTherapy, setWorkshopSelectedTherapy] = useState(null);
  const [workshopSelectedOutstation, setWorkshopSelectedOutstation] =
    useState(null);
    const [workshopSelectedOnline, setWorkshopSelectedOnline] =
    useState(null);
  const [workshopSelectedInstructor, setSelectedWorkshopInstructor] =
    useState(null);
    const [modalAdd_ecxelIsOpen, setAdd_excelModalIsOpen] = useState("");
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  const filter = [
    { value: "All", label: "All" },
    { value: "1", label: "Featured" },
    { value: "0", label: "Sponsored" },
    { value: "Gift", label: "Gift" },
  ];
  const month = [
    { value: "All", label: "All" },
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const year = [
    { value: "All", label: "All" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
  ];
  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  function getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    // const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  }

  const openAdd_workshop = () => {
    closeAdd_workshop();
    const currentDateTime = getCurrentDateTime();
    setDateTime(currentDateTime);
    setAdd_workshopModalIsOpen(true);
  };

  const closeAdd_workshop = () => {
    setAdd_workshopModalIsOpen(false);
  };
  const openUpdate_workshop = (
    workshopId,
    workshopName,
    workshopShort,
    workshopDescription,
    workshopKnowledge,
    workshopYouGet,
    workshopBring,
    workshopSuitable,
    wokshopCategory,
    wokshopSubCategory,
    workshopseats,
    workshopDuration,
    workshopPrice,
    workshopTherapy,
    workshopOutstation,
    workshopAddress,
    workshopCity,
    workshopLocation,
    workshopPostal,
    workshopGoogle,
    workshopEmail,
    workshopPhone,
    workshopWebsite,
    workshopStatus,
    workshopInstructor,
    workshopOnline
  ) => {
    setSelectedUserId(workshopId);
    setSelectedWorkshopName(workshopName);
    setSelectedWorkshopShortDescription(workshopShort);
    setSelectedWorkshopDescription(workshopDescription);
    setSelectedWorkshopKnowledge(workshopKnowledge);
    setSelectedWorkshopWhatYouGet(workshopYouGet);
    setSelectedWorkshopWhatBring(workshopBring);
    setSelectedWorkshopSuitable(workshopSuitable);
    setSelectedWorkshopCategory(wokshopCategory);
    setSelectedWorkshopSubCategory(wokshopSubCategory);
    setSelectedWorkshopSeats(workshopseats);
    setSelectedWorkshopDuration(workshopDuration);
    setSelectedWorkshopPrice(workshopPrice);
    setWorkshopSelectedTherapy(workshopTherapy);
    setWorkshopSelectedOutstation(workshopOutstation);
    setSelectedWorkshopAddress(workshopAddress);
    setSelectedWorkshopCity(workshopCity);
    setSelectedWorkshopLocation(workshopLocation);
    setSelectedWorkshopPostalCode(workshopPostal);
    setSelectedWorkshopGoogleCode(workshopGoogle);
    setSelectedWorkshopEmail(workshopEmail);
    setSelectedWorkshopPhone(workshopPhone);
    setSelectedWorkshopWebsite(workshopWebsite);
    setWorkshopSelectedStatus(workshopStatus);
    setSelectedWorkshopInstructor(workshopInstructor);
    setWorkshopSelectedOnline(workshopOnline);

    closeUpdate_workshop();
    setUpdate_workshopModalIsOpen(true);
  };

  const closeUpdate_workshop = () => {
    setUpdate_workshopModalIsOpen(false);
  };
  const openDelete_workshop = (workshopId) => {
    setSelectedUserId(workshopId);

    closeDelete_workshops();
    setDelete_workshopIsOpen(true);
  };

  const closeDelete_workshops = () => {
    setDelete_workshopIsOpen(null);
  };
  const openWorkshop_booking_slots = (id) => {
    setId(id);
    setWorkshop_booking_slotsIsOpen(true);
  };
  const closeWorkshop_booking_slots = () => {
    setWorkshop_booking_slotsIsOpen(false);
  };

  const openWorkshopReview = (id) => {
    setId(id);
    setWorkshopReviewModalIsOpen(true);
  };

  const closeWorkshopReview = () => {
    setWorkshopReviewModalIsOpen(false);
  };

  const [modalWorkshopWishlistIsOpen, setWorkshopWishlistModalIsOpen] =
    useState("");

  const openWorkshopWishlist = (id) => {
    setId(id);
    setWorkshopWishlistModalIsOpen(true);
  };

  const closeWorkshopWishlist = () => {
    setWorkshopWishlistModalIsOpen(false);
  };

  const [
    modalWorkshopTotalAppointmentIsOpen,
    setWorkshopTotalAppointmentModalIsOpen,
  ] = useState("");

  const openWorkshopTotalAppointment = (id) => {
    setId(id);
    setWorkshopTotalAppointmentModalIsOpen(true);
  };
  const closeWorkshopTotalAppointment = () => {
    setWorkshopTotalAppointmentModalIsOpen(false);
  };

  const [
    modalWorkshopActiveAppointmentIsOpen,
    setWorkshopActiveAppointmentModalIsOpen,
  ] = useState("");

  const openWorkshopActiveAppointment = (id) => {
    setId(id);
    setWorkshopActiveAppointmentModalIsOpen(true);
  };
  const closeWorkshopActiveAppointment = () => {
    setWorkshopActiveAppointmentModalIsOpen(false);
  };

  const openAdd_media = (workshopId) => {
    setSelectedUserId(workshopId);
    closeAdd_media();
    setAdd_MediaModalIsOpen(true);
  };

  const closeAdd_media = () => {
    setAdd_MediaModalIsOpen(false);
  };

  const openAdd_excel = () => {
    closeAdd_excel();
    setAdd_excelModalIsOpen(true);
  };

  const closeAdd_excel = () => {
    setAdd_excelModalIsOpen(false);
  };


  const toggleSwitch = async (workshopId) => {
    const newToggleState = toggleStates[workshopId] === 1 ? 0 : 1;
    console.log(newToggleState);

    // Update the local state
    setToggleStates((prevState) => ({
      ...prevState,
      [workshopId]: newToggleState,
    }));
    try {
      const response = await fetch(UpdateAdminWorkshopToggle, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          homepage: newToggleState === 1 ? "1" : "0",
          Workshop_id: workshopId,
        }),
      });

      const data = await response.json();
      console.log(data); // Log the server response
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const toggleSwitch2 = async (workshopId) => {
    const newToggleState2 = toggleStates2[workshopId] === 1 ? 0 : 1;
    console.log(newToggleState2);

    // Update the local state
    setToggleStates2((prevState) => ({
      ...prevState,
      [workshopId]: newToggleState2,
    }));
    try {
      const response = await fetch(UpdateAdminWorkshopToggle, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          featured: newToggleState2 === 1 ? "1" : "0",
          Workshop_id: workshopId,
        }),
      });

      const data = await response.json();
      console.log(data); // Log the server response
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  useEffect(() => {
    fetch(getAdminWorkshop)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const workshop = data.data.Workshop;
        setWorkshopData(workshop);
        console.log(workshop);
        const initialToggleStates = workshop.reduce((acc, workshop) => {
          acc[workshop.id] = workshop.homepage === "1" ? 1 : 0;
          return acc;
        }, {});

        setToggleStates(initialToggleStates);

        const initialToggleStates2 = workshop.reduce((acc, workshop) => {
          acc[workshop.id] = workshop.featured === "1" ? 1 : 0;
          return acc;
        }, {});

        setToggleStates2(initialToggleStates2);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  function getBgColorClass(index) {
    const colorClasses = ["red-bg", "blue-bg", "yellow-bg", "green-bg"]; // Define your color classes here
    const skipCount = 1; // Skip one row
    const effectiveIndex = Math.floor(index / skipCount); // Adjust index to account for skipped rows
    if ((effectiveIndex + 1) % 2 === 0) {
      // If it's an alternate row, return an empty string for the class
      return "";
    } else {
      // Otherwise, return the color class
      return colorClasses[index % colorClasses.length]; // Use modulo operator to cycle through color classes
    }
  }
  function handleDownloadClick() {
    window.open(workshopExcel, "_blank");
  }
  return (
    <div>
      <Header></Header>
      {modalAdd_workshopIsOpen && (
        <Add_workshop onClose={closeAdd_workshop} dateTime={dateTime} />
      )}
      {modalWorkshopReviewIsOpen && (
        <WorkshopReview onClose={closeWorkshopReview} id={id} />
      )}

      {modalWorkshopWishlistIsOpen && (
        <WorkshopWishlist onClose={closeWorkshopWishlist} id={id} />
      )}
      {modalWorkshopTotalAppointmentIsOpen && (
        <WorkshopTotalAppointment
          onClose={closeWorkshopTotalAppointment}
          id={id}
        />
      )}
      {modalWorkshopActiveAppointmentIsOpen && (
        <WorkshopActiveAppointment
          onClose={closeWorkshopActiveAppointment}
          id={id}
        />
      )}
      {modalAdd_mediaIsOpen && (
        <Add_mediaa onClose={closeAdd_media} workshopId={selectedUserId} />
      )}

      {modalUpdate_workshopIsOpen && (
        <Update_workshop
          workshopId={selectedUserId}
          workshopName={workshopSelectedName}
          workshopShort={workshopSelectedShortDescription}
          workshopDescription={workshopSelectedDescription}
          workshopKnowledge={workshopSelectedKnowledge}
          workshopYouGet={workshopSelectedWhatYouGet}
          workshopBring={workshopSelectedWhatBring}
          workshopSuitable={workshopSelectedSuitable}
          wokshopCategory={workshopSelectedCategory}
          wokshopSubCategory={workshopSelectedSubCategory}
          workshopseats={workshopSelectedSeats}
          workshopDuration={workshopSelectedDuration}
          workshopPrice={workshopSelectedPrice}
          workshopTherapy={workshopSelectedTherapy}
          workshopOutstation={workshopSelectedOutstation}
          workshopAddress={workshopSelectedAddress}
          workshopCity={workshopSelectedCity}
          workshopLocation={workshopSelectedLocation}
          workshopPostal={workshopSelectedPostalCode}
          workshopGoogle={workshopSelectedGoogleCode}
          workshopEmail={workshopSelectedEmail}
          workshopPhone={workshopSelectedPhone}
          workshopWebsite={workshopSelectedWebsite}
          workshopStatus={workshopSelectedStatus}
          workshopInstructor={workshopSelectedInstructor}
          workshopOnline={workshopSelectedOnline}
          onClose={closeUpdate_workshop}
        />
      )}
      {modalDelete_workshopIsOpen && (
        <Delete_workshop
          workshopId={selectedUserId}
          onClose={closeDelete_workshops}
        />
      )}
      {modalWorkshop_booking_slotsIsOpen && (
        <Workshop_booking_slots onClose={closeWorkshop_booking_slots} id={id} />
      )}
       {modalAdd_ecxelIsOpen && <AddWorkshopbyExcel onClose={closeAdd_excel} />}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Workshop</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Workshop</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-12 col-md-12 col-lg-12 row">
                <div class="child col-md-2" style={{ padding: "5px" }}>
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "37px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                      width: "100%",
                    }}
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    placeholder="Search..."
                  />
                </div>
                <div className="col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={filter}
                    placeholder="Filter By"
                    onChange={(selectedOption) =>
                      setSelectedFilter(selectedOption)
                    }
                  />
                </div>
                <div className="col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={status}
                    placeholder="Select status"
                    onChange={(selectedOption) =>
                      setSelectedStatus(selectedOption)
                    }
                  />
                </div>
                <div className="col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={month}
                    placeholder="Select Month"
                    onChange={(selectedOption) =>
                      setSelectedMonth(selectedOption)
                    }
                  />
                </div>
                <div className="col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={year}
                    placeholder="Select Year"
                    onChange={(selectedOption) =>
                      setSelectedYear(selectedOption)
                    }
                  />
                </div>

                <div
                  class=" col-md-2"
                  style={{ padding: "5px", display: "flex" }}
                >
                  <a>
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_workshop}
                      // onClick={handleAddWorkshopClick}
                    >
                      <i class="bi bi-person-add"></i> Add Workshop
                    </button>
                  </a>

                  <i
                    onClick={handleDownloadClick}
                    style={{
                      cursor: "pointer",
                      fontSize: "20px",
                      marginLeft: "10px",
                    }}
                    class="bi bi-download"
                  ></i>
                </div>
              </div>
              <div
                  class=" col-12 col-md-12 col-lg-12 row"
                  style={{ padding: "5px", display: "flex" }}
                >
                  <a class="col-md-2"></a>
                  <a class="col-md-2"></a>
                  <a class="col-md-2"></a>
                  <a class="col-md-3"></a>
                  <a class="col-md-3">
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_excel}
                    >
                      <i class="bi bi-person-add"></i> Add Workshop by Excel
                    </button>
                  </a>
                </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>

            <div className="table-responsive">
              {isLoading ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : filteredWorkshopData.length > 0 ? (
                <table className="table" style={{ padding: "0rem" }}>
                  {/* Table headers */}
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Home Page</th>
                      <th scope="col">Featured</th>
                      <th scope="col">Media Count</th>
                      <th scope="col">Action</th>
                      <th scope="col">Online</th>
                      <th scope="col">Slots</th>
                      <th scope="col">Status</th>
                      <th scope="col">Workshop Name</th>
                      <th scope="col">Instructor</th>
                      <th scope="col">Gift</th>
                      <th scope="col">Rating</th>

                      <th scope="col">Reviews</th>

                      <th scope="col">Share</th>
                      <th scope="col">Visit</th>
                      <th scope="col">Wishlisted</th>
                      <th scope="col">Total Appointments</th>
                      <th scope="col">Appointment Active</th>
                      <th scope="col">Package Type</th>
                      <th scope="col">Status Of Package</th>
                      <th scope="col">Outstation</th>
                      <th scope="col">Therapy</th>
                      
                      <th scope="col">Created On</th>
                     
                    
                    </tr>
                  </thead>
                  <tbody>
                    {filteredWorkshopData.map((workshop, index) => (
                      <tr key={workshop.id} class={getBgColorClass(index)}>
                        <th scope="row">{index + 1}</th>
                        {/* <td>{workshop.id}</td> */}
                        <td>
                          <Toggle
                            checked={toggleStates[workshop.id] === 1}
                            onChange={() => toggleSwitch(workshop.id)}
                          />
                        </td>
                        <td>
                          <Toggle
                            checked={toggleStates2[workshop.id] === 1}
                            onChange={() => toggleSwitch2(workshop.id)}
                          />
                        </td>
                        <td>{workshop.media_count}</td>
                        <td>
                          <div className="social-links mt-2">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                openUpdate_workshop(
                                  workshop.id,
                                  workshop.name,
                                  workshop.short_description,
                                  workshop.description,
                                  workshop.knowledge_require,
                                  workshop.what_you_will_get,
                                  workshop.what_to_bring,
                                  workshop.suitable_for,
                                  {
                                    value: workshop.category.id,
                                    label: workshop.category.name,
                                  },
                                  {
                                    value: workshop.subcategory.id,
                                    label: workshop.subcategory.name,
                                  },
                                  workshop.total_seats,
                                  workshop.duration_in_hour,
                                  workshop.price,
                                  {
                                    value: workshop.id,
                                    label: workshop.therapy,
                                  },
                                  {
                                    value: workshop.id,
                                    label: workshop.outstation,
                                  },
                                  
                                  workshop.address,
                                  {
                                    value: workshop.city.id,
                                    label: workshop.city.name,
                                  },
                                  {
                                    value: workshop.area.id,
                                    label: workshop.area.name,
                                  },
                                  workshop.pincode,
                                  workshop.google_location_link,
                                  workshop.email,
                                  workshop.mobileNumber,
                                  workshop.website,
                                  {
                                    value: workshop.id,
                                    label: workshop.status,
                                  },
                                  {
                                    value: workshop.workshopInstructor.id,
                                    label: workshop.workshopInstructor.name,
                                  },
                                  {
                                    value: workshop.id,
                                    label: workshop.online,
                                  },

                                )
                              }
                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => openDelete_workshop(workshop.id)}
                            >
                              <i class="bi bi-trash3"></i>
                            </a>
                            &nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => openAdd_media(workshop.id)}
                            >
                              <i class="bi bi-camera"></i>
                            </a>
                          </div>
                        </td>
                        <td>{workshop.online}</td>
                        <td>
                          <div className="social-links mt-2">
                            <a
                              onClick={() =>
                                openWorkshop_booking_slots(workshop.id)
                              }
                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                          </div>
                        </td>
                        <td>{workshop.status}</td>
                        <td>{workshop.name}</td>
                        <td>
                          {workshop.workshopInstructor
                            ? workshop.workshopInstructor.name
                            : "-"}
                          <br />
                          {workshop.workshopInstructor
                            ? workshop.workshopInstructor.email
                            : "-"}
                          <br />
                          {workshop.workshopInstructor
                            ? workshop.workshopInstructor.mobileNumber
                            : "-"}
                        </td>
                        <td>{workshop.gift_count}</td>
                        <td>{workshop.ratings_count}</td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openWorkshopReview(workshop.id)}
                          >
                            {workshop.reviews_count}
                          </a>
                        </td>

                        <td>{workshop.share_count}</td>
                        <td>{workshop.visit}</td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openWorkshopWishlist(workshop.id)}
                          >
                            {workshop.wishlist_count}
                          </a>
                        </td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              openWorkshopTotalAppointment(workshop.id)
                            }
                          >
                            {workshop.total_appointments}
                          </a>
                        </td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              openWorkshopActiveAppointment(workshop.id)
                            }
                          >
                            {workshop.active_appointments}
                          </a>
                        </td>
                        <td>
                          {workshop.package ? workshop.package.duration : "-"}
                        </td>
                        <td>
                          {workshop.package ? workshop.package.status : "-"}
                        </td>
                        <td>{workshop.outstation}</td>
                        <td>{workshop.therapy}</td>
                      
                        <td>{formatCreatedAt(workshop.created_at)}</td>
                       

                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredWorkshopData.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Workshop;
