import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import Add_Instructor_Assigned_packages from "../../Components/instructor_comp/add_assignedpackages.js";
import Update_Instructor_Assigned_packages from "../../Components/instructor_comp/update_assignedPackages.js";
import Delete_Instructor_Assigned_packages from "../../Components/instructor_comp/delete_assignedpackages.js";
import "../../Api/apiUrls.js";
import { getInstructorAssignedPackagesId } from "../../Api/apiUrls";
function Instructor_Assigned_packages() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [
    modalAdd_Instructor_Assigned_packagesIsOpen,
    setAdd_Instructor_Assigned_packagesModalIsOpen,
  ] = useState("");
  const [
    modalUpdate_Instructor_Assigned_packagesIsOpen,
    setUpdate_Instructor_Assigned_packagesModalIsOpen,
  ] = useState(false);
  const [
    modalDelete_Instructor_Assigned_packagesIsOpen,
    setDelete_Instructor_Assigned_packagesIsOpen,
  ] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const openAdd_Instructor_Assigned_packages = () => {
    closeAdd_Instructor_Assigned_packages();
    setAdd_Instructor_Assigned_packagesModalIsOpen(true);
  };
  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };
  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption);
  };

  const handleTypeChange = (selectedOption) => {
    setSelectedType(selectedOption);
  };

  const handlePackageChange = (selectedOption) => {
    setSelectedPackage(selectedOption);
  };

  const handleTransactionChange = (selectedOption) => {
    setSelectedTransaction(selectedOption);
  };

  const closeAdd_Instructor_Assigned_packages = () => {
    setAdd_Instructor_Assigned_packagesModalIsOpen(false);
  };
  const openUpdate_Instructor_Assigned_packages = () => {
    closeUpdate_Instructor_Assigned_packages();
    setUpdate_Instructor_Assigned_packagesModalIsOpen(true);
  };

  const closeUpdate_Instructor_Assigned_packages = () => {
    setUpdate_Instructor_Assigned_packagesModalIsOpen(false);
  };
  const openDelete_Instructor_Assigned_packages = (userId) => {
    setSelectedUserId(userId);
    closeDelete_Instructor_Assigned_packages();
    setDelete_Instructor_Assigned_packagesIsOpen(true);
  };

  const closeDelete_Instructor_Assigned_packages = () => {
    setDelete_Instructor_Assigned_packagesIsOpen(null);
  };

  const filter = [
    { value: "All", label: "All" },
    { value: "Featured", label: "Featured" },
    { value: "Sponsored", label: "Sponsored" },
    { value: "Gift", label: "Gift" },
  ];

  const type = [
    { value: "All", label: "All" },
    { value: "Workshop", label: "Workshop" },
    // { value: "Venue", label: "Venue" },
    { value: "News", label: "News" },
  ];
  const transaction = [
    { value: "All", label: "All" },
    { value: "Success", label: "Success" },
    { value: "Failed", label: "Failed" },
    { value: "On Hold", label: "On Hold" },
  ];
  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Expired", label: "Expired" },
    { value: "Publish", label: "Publish" },
  ];
  const Package = [
    { value: "All", label: "All" },
    { value: "Annual", label: "Annual" },
    { value: "Month", label: "Monthly" },
    { value: "Quarter", label: "Quarterly" },
    { value: "Week", label: "Weekly" },
    { value: "Daily", label: "Daily" },
  ];

  const instructorId = localStorage.getItem("user_id");
  const [package_Management, setPackage_Management] = useState([]);

  useEffect(() => {
    fetch(getInstructorAssignedPackagesId + instructorId)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const package_Management = data.data.PackageManagement;
        console.log(package_Management);
        setPackage_Management(package_Management);
        console.log(setPackage_Management(package_Management));
        console.log(package_Management.length);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Package Management value:", package_Management);
      });
  }, []);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPackageManagement, setFilteredPackageManagement] = useState(
    []
  );
  // useEffect(() => {
  //   const hasReloaded = localStorage.getItem('hasReloaded');
  //   if (!hasReloaded) {
  //     // alert("hiiiiiiiiiii")
  //     localStorage.setItem('hasReloaded', 'true');
  //     window.location.reload();
  //   }
  // }, []);
  useEffect(() => {
    console.log(package_Management.length);
  }, [package_Management]);
  useEffect(() => {
    // Filter packageManagement based on selected filters and search query
    const newFilteredPackageManagement = package_Management.filter((item) => {
      const statusCondition =
        !selectedStatus ||
        selectedStatus.value === "All" ||
        item.status === selectedStatus.value;
      const filterCondition =
        !selectedFilter ||
        selectedFilter.value === "All" ||
        item.filter === selectedFilter.value;
      const typeCondition =
        !selectedType ||
        selectedType.value === "All" ||
        item.package.type === selectedType.value;
      const packageCondition =
        !selectedPackage ||
        selectedPackage.value === "All" ||
        item.package.duration === selectedPackage.value;
      const transactionCondition =
        !selectedTransaction ||
        selectedTransaction.value === "All" ||
        item.transaction?.transaction_status === selectedTransaction.value;

      const searchCondition = Object.values(item).some((value) => {
        if (typeof value === "object" && value !== null) {
          return Object.values(value).some((subValue) => {
            if (typeof subValue === "string") {
              return subValue.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
          });
        } else if (typeof value === "string") {
          return value.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return false;
      });

      return (
        statusCondition &&
        filterCondition &&
        typeCondition &&
        packageCondition &&
        transactionCondition &&
        searchCondition
      );
    });

    // Update the state with the filtered packageManagement
    setFilteredPackageManagement(newFilteredPackageManagement);
  }, [
    selectedStatus,
    selectedFilter,
    selectedType,
    selectedPackage,
    selectedTransaction,
    searchQuery,
    package_Management,
  ]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);

    // Format the date part
    const datePart = createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    // Format the time part
    const timePart = createdAtDate.toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",

      hour12: true,
    });

    return `${datePart} - ${timePart}`;
  };
  return (
    <div>
      <Header></Header>
      {modalAdd_Instructor_Assigned_packagesIsOpen && (
        <Add_Instructor_Assigned_packages
          onClose={closeAdd_Instructor_Assigned_packages}
        />
      )}
      {modalUpdate_Instructor_Assigned_packagesIsOpen && (
        <Update_Instructor_Assigned_packages
          onClose={closeUpdate_Instructor_Assigned_packages}
        />
      )}
      {modalDelete_Instructor_Assigned_packagesIsOpen && (
        <Delete_Instructor_Assigned_packages
          userId={selectedUserId}
          onClose={closeDelete_Instructor_Assigned_packages}
        />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Package Management</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div class="child col-md-2" style={{ padding: "5px" }}>
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "37px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                      width: "100%",
                    }}
                    placeholder="Search..."
                    onChange={handleSearchChange}
                    value={searchQuery}
                  />
                </div>
                <div class=" col-md-2 " style={{ padding: "5px" }}>
                  <Select
                    options={status}
                    placeholder="Select Status"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                  ></Select>
                </div>

                <div class=" col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={type}
                    placeholder="Select type"
                    value={selectedType}
                    onChange={handleTypeChange}
                  ></Select>
                </div>

                <div class=" col-md-3" style={{ padding: "5px" }}>
                  <Select
                    options={Package}
                    placeholder="Select package"
                    value={selectedPackage}
                    onChange={handlePackageChange}
                  ></Select>
                </div>
                <div class="  col-md-3 " style={{ padding: "5px" }}>
                  <Select
                    options={transaction}
                    placeholder="Select transaction"
                    value={selectedTransaction}
                    onChange={handleTransactionChange}
                  ></Select>
                </div>
              </div>
            </div>
            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : package_Management.length > 0 ? (
                <table class="table table-striped  ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.no</th>
                      <th scope="col">Details</th>

                      <th scope="col">Status</th>

                      <th scope="col">Package Name</th>
                      <th scope="col">Order ID</th>
                      <th scope="col">Date of Activation</th>
                      <th scope="col">Date of Expiry</th>
                      <th scope="col">Package Type</th>
                      <th scope="col"> Type</th>
                      <th scope="col">Package Amount</th>
                      <th scope="col">Payment Mode</th>
                      <th scope="col">Transaction Status</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredPackageManagement.map(
                      (package_Management, index) => (
                        <tr key={package_Management.id}>
                          <th scope="row">{index + 1}</th>

                          <td>
                            {package_Management.news?.title ||
                              package_Management.workshop?.name ||
                              "--"}
                            <br />
                          </td>

                          <td>
                            {package_Management.status || "--"}
                            <br />
                          </td>
                          <td>
                            {package_Management.package.name || "--"}
                            <br />
                          </td>
                          <td>
                            {package_Management.transaction.order_id || "--"}
                            <br />
                          </td>
                          <td>
                            {formatCreatedAt(
                              package_Management.dateOfActivation || "--"
                            )}
                            <br />
                          </td>
                          <td>
                            {formatCreatedAt(
                              package_Management.dateOfExpiry || "--"
                            )}
                            <br />
                          </td>
                          <td>
                            {package_Management.package.duration || "--"}
                            <br />
                          </td>
                          <td>
                            {package_Management.package.type || "--"}
                            <br />
                          </td>
                          <td>
                            {package_Management.package.price || "--"}
                            <br />
                          </td>
                          <td>
                            {package_Management.transaction.mode || "--"}

                            <br />
                          </td>
                          <td>
                            {package_Management.transaction
                              .transaction_status || "--"}

                            <br />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredPackageManagement.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Instructor_Assigned_packages;
