import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_categories.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { addCategories } from "../../Api/apiUrls";
function Add_categories({ onClose }) {
  const [modalAdd_categoriesIsOpen, setAdd_categoriesModalIsOpen] =
    useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryName, setcategoryName] = useState("");
  const [categoryDescription, setcategoryDescription] = useState("");
  const [categoryType, setcategoryType] = useState("");
  const [categoryStatus, setcategoryStatus] = useState("");
  const [isAddingcategories, setIsAddingcategories] = useState(false);
  const [categoryHomepage, setcategoryHomepage] = useState("");

  const closeAdd_categories = () => {
    setAdd_categoriesModalIsOpen(false);
  };

  async function addCategory(
    categoryName,
    categoryDescription,
    selectedCategory,
    categoryStatus,
    categoryType,
    categoryHomepage
  ) {
    try {
      const formData = new FormData();
      formData.append("name", categoryName);
      formData.append("description", categoryDescription);

      formData.append("status", selectedCategory);
      formData.append("type", categoryStatus);

      formData.append("homepage", categoryHomepage);
      console.log(categoryHomepage);
      const response = await fetch(addCategories, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        return true; // Category added successfully
      } else {
        throw new Error("Failed to add category");
      }
    } catch (error) {
      console.error("Error adding category:", error);
      return false; // Category addition failed
    }
  }
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  const handleAddCategory = async () => {
    if (!categoryName) {
      alert("Please fill in all fields");
      return;
    }

    setIsAddingcategories(true);

    const result = await addCategory(
      categoryName,
      categoryDescription,
      categoryStatus,
      categoryType,
      categoryHomepage,
      selectedCategory
    );

    setIsAddingcategories(false);

    if (result) {
      alert("Category Added Successfully");
      setSelectedCategory("");
      console.log(categoryName);
      setcategoryName("");
      window.location.reload();
      // setIconFile(null);
      onClose(); // Close the modal
    } else {
      alert("Error Adding Category");
    }
  };

  const Status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const Type = [
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
  ];
  const homepageoptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const handleTypeChange = (selectedOption) => {
    setcategoryType(selectedOption.value);
    console.log(selectedOption.value);
  };
  const handleStatusChange = (selectedOption) => {
    setcategoryStatus(selectedOption.value);
  };
  const handleHomeChange = (selectedOption2) => {
    setcategoryHomepage(selectedOption2.value);
    console.log(selectedOption2.value);
  };

  function reloadPage() {
    window.location.reload();
  }

  return (
    <div>
      {modalAdd_categoriesIsOpen && (
        <Add_categories onClose={closeAdd_categories} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "auto",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add Categories</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={categoryName}
                    onChange={(e) => setcategoryName(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description
                  </label>
                  <textarea
                    type="text"
                    id="selectedImg"
                    class="form-control"
                    value={categoryDescription}
                    onChange={(e) => setcategoryDescription(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label
                    class="small mb-1"
                    for="inputFullName"
                    value={categoryType}
                    onChange={(e) => setcategoryType(e.target.value)}
                  >
                    Category Type
                  </label>
                  <Select onChange={handleTypeChange} options={Type}></Select>
                </div>
                <div class="form-group">
                  <label
                    class="small mb-1"
                    for="inputFullName"
                    value={categoryStatus}
                    onChange={(e) => setcategoryStatus(e.target.value)}
                  >
                    Status
                  </label>
                  <Select
                    onChange={handleStatusChange}
                    options={Status}
                  ></Select>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingcategories === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAddCategory}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_categories;
