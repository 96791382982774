import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import "../../Api/apiUrls.js";
import { API_URL, updateUser, getUserById, getPackageForAnnual } from "../../Api/apiUrls";
import PackageInvoice from "../../Components/instructor_comp/packageInvoice.js";

function ViewInstProfile() {
  const [userData, setUserData] = useState("");
  const [fullName, setFullName] = useState("");
  const [bio, setBio] = useState("");
  const [mobileNo, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [bankname, setBankName] = useState("");
  const [account, setAccount] = useState("");
  const [code, setCode] = useState("");
  const [pancard, setPanCard] = useState("");
  const [country, setCountry] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [city, setCity] = useState("");
  const [youtube, setYoutube] = useState("");
  const [aadharcard, setAadharcard] = useState("");
  const [gstno, setGstno] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [file, setIconFile] = useState();
  const [modalPackageInvoiceIsOpen, setPackageInvoiceIsOpen] = useState("");
  const [packageId, setPackageId] = useState("");
  const [packageName, setPackageName] = useState("");
  const [packageType, setPackageType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  function reloadPage() {
    window.location.reload();
  }

  const openPackageInvoice = (idpackage, namepackage, typepackage) => {
    setPackageId(idpackage);
    setPackageName(namepackage);
    setPackageType(typepackage);
    setPackageInvoiceIsOpen(true);
  };

  const closePackageInvoice = () => {
    setPackageInvoiceIsOpen(false);
  };



  // useEffect(() => {
  //   const hasReloaded = localStorage.getItem('hasReloaded');
  //   if (!hasReloaded) {
  //     // alert("hiiiiiiiiiii")
  //     localStorage.setItem('hasReloaded', 'true');
  //     window.location.reload();
  //   }
  // }, []);

  

  const userId = localStorage.getItem("user_id");
  useEffect(() => {

    // Fetch user data from the API
    fetch(`${getUserById}${userId}`)
      .then((response) => response.json())
      .then((data) => {
        const user = data.data.user;
        console.log(user.name);
        console.log(user.status);
        localStorage.setItem("userStatus", user.status);
        setFullName(user.name);
        setIconFile(user.profile_image);
        setBio(user.bio);
        setMobile(user.mobileNumber);
        setAddress(user.address);
        setCity(user.city);
        setEmail(user.email);
        setCountry(user.country);
        setFacebook(user.facebook);
        setInstagram(user.instagram);
        setYoutube(user.youtube);
        setTwitter(user.twitter);
        setBankName(user.bank_name);
        setAccount(user.bank_account_number);
        setCode(user.bank_ifsc_code);
        setPanCard(user.pan_card_number);
        setAadharcard(user.aadhar_card_number);
        setGstno(user.gst_number);
        setBusinessName(user.business_name);
        // reloadPage();
      })
      .catch((error) => console.error("Error fetching user data:", error));
  }, []);



  const [editable, setEditable] = useState(false);
  const [editedData, setEditedData] = useState({ ...userData });
  const [isReadOnly, setReadOnly] = useState(true);

  const handleEditClick = () => {
    setReadOnly(false);
    setEditable(true);

  };

  const handleSaveClick = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", userId);
      formData.append("name", fullName);
      formData.append("profile_image", file);
      formData.append("bio", bio);
      formData.append("mobileNumber", mobileNo);
      formData.append("email", email);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("country", country);
      formData.append("facebook", facebook);
      formData.append("instagram", instagram);
      formData.append("youtube", youtube);
      formData.append("twitter", twitter);
      formData.append("bank_name", bankname);
      formData.append("bank_account_number", account);
      formData.append("bank_ifsc_code", code);
      formData.append("pan_card_number", pancard);
      formData.append("aadhar_card_number", aadharcard);
      formData.append("gst_number", gstno);
      formData.append("business_name", businessName);

      const response = await fetch(`${updateUser}`, {
        method: "PUT",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Failed to save data: ${response.statusText}`);
      }

      console.log("Data saved successfully");
      reloadPage();

      setEditable(false);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setIconFile(file);
    console.log(file);

    const formData = new FormData();
    formData.append("profile_image", file);
    const reader = new FileReader();
    reader.onload = function (e) {
      const imgElement = document.getElementById("selectedImg");
      imgElement.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };
  // const handleChange = (e) => {
  //   const { id, value } = e.target;
  //   setEditedData((prevData) => ({ ...prevData, [id]: value }));
  // };

  const [packages, setPackages] = useState([]);

  useEffect(() => {
    fetch(`${getPackageForAnnual}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const packages = data.data.Package.filter(
          (packages) => packages.status === "Active"
        );
        setPackages(packages);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const userStatus = localStorage.getItem("userStatus");
  console.log(userStatus);
  return (
    <div>
      {modalPackageInvoiceIsOpen && (
        <PackageInvoice onClose={closePackageInvoice} idpackage={packageId} namepackage={packageName} typepackage={packageType} />
      )}
      <Header></Header>
      <main id="main" class="main">
        <div class="pagetitle">
          <h1> Profile</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item active"> Profile</li>
            </ol>
          </nav>
        </div>
        <section class="section">
          <div class="card p-3">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-12" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    {
                      <button
                        id="btnNewUser"
                        type="button"
                        // data-toggle="modal"
                        // data-target="#modalAdd"
                        class=" btn-round"
                        onClick={handleEditClick}
                      >
                        <i class="bi bi-pencil"></i> Edit Profile
                      </button>
                    }
                  </a>
                </div>
              </div>
            </div>
            <form style={{ padding: "0px 30px 30px 30px" }}>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Full Name<font color="red"></font>
                </label>
                <input
                  class="form-control"
                  id="inputFullName"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={fullName}
                  readOnly={isReadOnly}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputDocument">
                  Cover Image
                </label>
                <br />
                <input
                  type="file"
                  id="selectedImg"
                  name="document"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                />
                <img
                  id="selectedImg"
                  src={`${API_URL}/${file}`}
                  // alt={category.name || 'No Name'}
                  style={{ width: "60px", height: "60px" }}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Bio
                </label>
                <textarea
                  class="form-control"
                  id="inputBio"
                  type="text"
                  placeholder=""
                  style={{ height: "100px", pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={bio}
                  readOnly={isReadOnly}
                  onChange={(e) => setBio(e.target.value)}
                />
              </div>

              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Mobile No.
                </label>
                <input
                  class="form-control"
                  id="inputNo"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={mobileNo}
                  readOnly={isReadOnly}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Email
                </label>
                <input
                  class="form-control"
                  id="inputEmail"
                  type="email"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={email}
                  readOnly={isReadOnly}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {!email.includes("@") && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    Please enter a valid email address.
                  </p>
                )}
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Address
                </label>
                <input
                  class="form-control"
                  id="inputAddress"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={address}
                  readOnly={isReadOnly}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  City
                </label>
                <input
                  class="form-control"
                  id="inputAddress"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={city}
                  readOnly={isReadOnly}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Country
                </label>
                <input
                  class="form-control"
                  id="inputCountry"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={country}
                  readOnly={isReadOnly}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>

              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Bank Name
                </label>
                <input
                  class="form-control"
                  id="inputCountry"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={bankname}
                  readOnly={isReadOnly}
                  onChange={(e) => setBankName(e.target.value)}
                />
              </div>

              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Account No.
                </label>
                <input
                  class="form-control"
                  id="inputCountry"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={account}
                  readOnly={isReadOnly}
                  onChange={(e) => setAccount(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  IFSC Code
                </label>
                <input
                  class="form-control"
                  id="inputCountry"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={code}
                  readOnly={isReadOnly}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>

              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  PAN Card No.
                </label>
                <input
                  class="form-control"
                  id="inputCountry"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={pancard}
                  readOnly={isReadOnly}
                  onChange={(e) => setPanCard(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Aadhar Card No.
                </label>
                <input
                  class="form-control"
                  id="inputCountry"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={aadharcard}
                  readOnly={isReadOnly}
                  onChange={(e) => setAadharcard(e.target.value)}
                />
              </div>

              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  GST No.
                </label>
                <input
                  class="form-control"
                  id="inputCountry"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={gstno}
                  readOnly={isReadOnly}
                  onChange={(e) => setGstno(e.target.value)}
                />
              </div>

              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Business Name
                </label>
                <input
                  class="form-control"
                  id="inputCountry"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={businessName}
                  readOnly={isReadOnly}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Facebook
                </label>
                <input
                  class="form-control"
                  id="inputFacebook"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={facebook}
                  readOnly={isReadOnly}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Instagram
                </label>
                <input
                  class="form-control"
                  id="inputInstagram"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={instagram}
                  readOnly={isReadOnly}
                  onChange={(e) => setInstagram(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Youtube
                </label>
                <input
                  class="form-control"
                  id="inputInstagram"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={youtube}
                  readOnly={isReadOnly}
                  onChange={(e) => setYoutube(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label class="small mb-1" for="inputFullName">
                  Twitter
                </label>
                <input
                  class="form-control"
                  id="inputTwitter"
                  type="text"
                  placeholder=""
                  style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
                  value={twitter}
                  readOnly={isReadOnly}
                  onChange={(e) => setTwitter(e.target.value)}
                />
              </div>


              <div className="form-group">
                {editable && (
                  <button
                    id="btnNewUser"
                    type="button"
                    class=" btn-round"
                    onClick={handleSaveClick}
                  >
                    Save Changes
                  </button>
                )}
              </div>
            </form>
            {userStatus === 'Inactive' && (
              <div className="row col-md-12">
                <h5 style={{ paddingLeft: '30px',marginBottom:'20px' }}>Buy Package for Annual Hosting</h5>
                {packages.map((packageItem) => (
                  <div key={packageItem.id} className="col-md-4" style={{marginLeft:"18px"}}>
                    <div className="card" style={{ boxShadow: "0px 0 30px rgba(1, 41, 112, 0.1)" }}>
                      <div className="card-body">
                        <h5 className="card-title">
                          <b>{packageItem.name}</b>
                        </h5>
                        <p className="card-text">Price: {packageItem.price}</p>
                        <p className="card-text">Duration: {packageItem.duration}</p>
                        <a
                          href="#"
                          className="btn btn-dark"
                          onClick={() =>
                            openPackageInvoice(packageItem.id, packageItem.name, packageItem.type)
                          }
                        >
                          Buy
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}


          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default ViewInstProfile;
