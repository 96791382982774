import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_banner.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { addBanner } from "../../Api/apiUrls";
function Add_banners({ onClose }) {
  const [bannerTittle, setBannerTittle] = useState("");
  const [bannerStatus, setStatus] = useState("");
  const [file, setIconFile] = useState();
  const [modalAdd_bannersIsOpen, setAdd_BannerModalIsOpen] = useState("");

  const [isAddingBanner, setIsAddingBanner] = useState(false);

  const status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const closeAdd_banners = () => {
    setAdd_BannerModalIsOpen(false);
  };
  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption.value);
  };

  function reloadPage() {
    window.location.reload();
  }
  async function add_banner(bannerTittle, file, bannerStatus) {
    try {
      const formData = new FormData();
      formData.append("title", bannerTittle);
      formData.append("photo", file);
      formData.append("status", bannerStatus);

      const response = await fetch(addBanner, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        return true; // Category added successfully
      } else {
        throw new Error("Failed to add banner");
      }
    } catch (error) {
      console.error("Error adding banner:", error);
      return false; // Category addition failed
    }
  }

  const handleAdd_banner = async () => {
    if (!bannerTittle) {
      alert("Please fill in all fields");
      return;
    }

    setIsAddingBanner(true);

    const result = await add_banner(bannerTittle, file, bannerStatus);

    setIsAddingBanner(false);

    if (result) {
      alert("Banner Added Successfully");
      console.log(setBannerTittle);
      setBannerTittle("");
      // window.location.reload();
      setIconFile(null);
      onClose(); // Close the modal
    } else {
      alert("Error Adding Banner");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setIconFile(file);
    console.log(file);

    const formData = new FormData();
    formData.append("image", file);
    const reader = new FileReader();
    reader.onload = function (e) {
      const imgElement = document.getElementById("selectedImg");
      imgElement.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <div>
      {modalAdd_bannersIsOpen && <Add_banners onClose={closeAdd_banners} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "auto",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add Banners</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  // reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Tittle
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={bannerTittle}
                    onChange={(e) => setBannerTittle(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputDocument">
                    Cover Image
                  </label>
                  <br />
                  <input
                    type="file"
                    id="selectedImg"
                    name="document"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
                <div class="form-group">
                  <label
                    class="small mb-1"
                    for="inputFullName"
                    value={bannerStatus}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    Status
                  </label>
                  <Select
                    onChange={handleStatusChange}
                    options={status}
                  ></Select>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  // reloadPage();
                }}
              >
                Close
              </button>
              {isAddingBanner === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAdd_banner}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_banners;
