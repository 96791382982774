import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import News_eye from "../../Components/instructor_comp/eye_news.js";
import "../../Api/apiUrls.js";
import { getInstructorNews } from "../../Api/apiUrls";
import BuyPackagesNews from "../../Components/instructor_comp/buyPackagesNews.js";
import NewsComments from "../../Components/Admin_components/newsComment.js";
import NewsSave from "../../Components/Admin_components/newsSave.js";
import "../../Api/apiUrls.js";
import { API_URL } from "../../Api/apiUrls";

function InstructorNews() {
  const [news, setNews] = useState(false);
  const [id, setId] = useState("");
  const [modalNewsCommentsIsOpen, setNewsCommentsIsOpen] = useState(false);
  const [modalNewsSaveIsOpen, setNewsSaveIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [modalNews_eyeIsOpen, setNews_eyeModalIsOpen] = useState("");
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [modalBuyPackageNewsIsOpen, setBuyPackageNewsIsOpen] = useState("");

  const openBuyPackageNews = () => {
    closeBuyPackageNews();
    setBuyPackageNewsIsOpen(true);
  };
  const closeBuyPackageNews = () => {
    setBuyPackageNewsIsOpen(false);
  };

  const drop = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };
  const openNews_eye = (newsId) => {
    closeNews_eye();
    setSelectedNewsId(newsId);
    setNews_eyeModalIsOpen(true);
  };

  const closeNews_eye = () => {
    setNews_eyeModalIsOpen(false);
  };

  const openNewsComments = (id) => {
    closeNewsComments();
    setId(id);
    setNewsCommentsIsOpen(true);
  };

  const closeNewsComments = () => {
    setNewsCommentsIsOpen(null);
  };

  const openNewsSave = (id) => {
    closeNewsSave();
    setId(id);
    setNewsSaveIsOpen(true);
  };

  const closeNewsSave = () => {
    setNewsSaveIsOpen(null);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredBlogs = Array.isArray(news)
    ? news.filter((news) => {
        const isStatus =
          !selectedStatus ||
          selectedStatus === "All" ||
          news.status === selectedStatus;
        const values = Object.values(news).map((value) =>
          typeof value === "string" ? value.toLowerCase() : ""
        );
        const isSearchMatch = values.some((value) =>
          value.includes(searchQuery.toLowerCase())
        );
        return isStatus && isSearchMatch;
      })
    : [];

  const instructorId = localStorage.getItem("user_id");
  // useEffect(() => {
  //   const hasReloaded = localStorage.getItem('hasReloaded');
  //   if (!hasReloaded) {
  //     // alert("hiiiiiiiiiii")
  //     localStorage.setItem('hasReloaded', 'true');
  //     window.location.reload();
  //   }
  // }, []);
  
  useEffect(() => {
    fetch(getInstructorNews + instructorId)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const news = data.data.BlogsAndNews;
        setNews(news);
        console.log(news);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);

    // Format the date part
    const datePart = createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    // Format the time part
    const timePart = createdAtDate.toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",

      hour12: true,
    });

    return `${datePart} - ${timePart}`;
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  return (
    <div>
      <Header></Header>
      {modalNews_eyeIsOpen && (
        <News_eye onClose={closeNews_eye} newsId={selectedNewsId} />
      )}
      {modalBuyPackageNewsIsOpen && (
        <BuyPackagesNews onClose={closeBuyPackageNews} />
      )}

      {modalNewsCommentsIsOpen && (
        <NewsComments onClose={closeNewsComments} id={id} />
      )}
      {modalNewsSaveIsOpen && <NewsSave onClose={closeNewsSave} id={id} />}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>News</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">News</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    onChange={handleSearchChange}
                    value={searchQuery}
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
                <div className="child ml-2">
                  <Select
                    options={drop}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                  ></Select>
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    {
                      <button
                        id="btnNewUser"
                        type="button"
                        data-toggle="modal"
                        data-target="#modalAdd"
                        class=" btn-round"
                        onClick={openBuyPackageNews}
                      >
                        <i class="bi bi-box-arrow-in-up"></i> Buy Packages
                      </button>
                    }
                  </a>
                </div>
              </div>
            </div>
            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            ></div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : news.length > 0 ? (
                <table class="table table-striped " style={{ padding: "0rem" }}>
                  <thead>
                    <tr>
                      <th scope="col">Sr.no</th>
                      <th scope="col">Image</th>
                      <th scope="col">Name</th>
                      <th scope="col">Order Id</th>
                      <th scope="col">Instructor</th>
                      <th scope="col">Comments</th>
                      <th scope="col">Visit</th>
                      <th scope="col">Share</th>
                      <th scope="col">Keywords</th>
                      <th scope="col">PackageType</th>
                      {/* <th scope="col"> Status Package Type</th> */}
                      {/* <th scope="col">Author</th> */}
                      <th scope="col">Created On</th>
                      <th scope="col">Publish On</th>
                      <th scope="col">Expiring On</th>
                      <th scope="col">Status</th>
                      <th scope="col">Save</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredBlogs.map((news, index) => (
                      <tr key={news.id}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {news.photo && (
                            <img
                              src={`${API_URL}/${news.photo}` || "-"}
                              style={{ height: "50px", width: "50px" }}
                            />
                          )}
                        </td>
                        <td>{news.title || "---"}</td>
                        <td>{news.order_id || "---"}</td>
                        <td>
                          {news.news_author.name || "---"}
                          <br />
                          {news.news_author.mobileNumber || "---"}
                          <br />
                          {news.news_author.email || "---"}
                          <br />
                        </td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openNewsComments(news.id)}
                          >
                            {news.comments}
                          </a>
                        </td>
                        <td>{news.visit || "---"}</td>
                        <td>{news.share || "---"}</td>
                        <td>{news.keywords || "---"}</td>
                        <td>{news.package.duration || "---"}</td>
                        {/* <td>{news.package_type_status || "---"}</td> */}
                        {/* <td>{news.user || "-"}</td> */}
                        <td>{formatCreatedAt(news.created_on)}</td>
                        <td>{formatCreatedAt(news.created_at)}</td>
                        <td>{formatCreatedAt(news.expiring_on)}</td>

                        <td>{news.status || "-"}</td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openNewsSave(news.id)}
                          >
                            {news.save_count}
                          </a>
                        </td>
                        <td>
                          <div className="social-links mt-2">
                            <a>
                              <i
                                class="bi bi-eye"
                                style={{ cursor: "pointer" }}
                                // onClick={openNews_eye}
                                onClick={() => openNews_eye(news.id)}
                              ></i>
                            </a>
                            &nbsp;&nbsp;
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredBlogs.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default InstructorNews;
