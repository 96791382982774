import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_appointment.css";
import { addFaqPage } from "../../Api/apiUrls";

function Add_faq({ onClose }) {
  const [modalAdd_faqIsOpen, setAdd_faqModalIsOpen] = useState("");
  const [faqQuestion, setFaqQuestion] = useState("");
  const [faqAnswer, setFaqAnswer] = useState("");
  const [faqPreference, setFaqPreference] = useState("");
  const [isAddingaFaq, setIsAddingFaq] = useState(false);

  const closeAdd_faq = () => {
    setAdd_faqModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }

  async function addFaq(faqQuestion, faqAnswer, faqPreference) {
    try {
      const formData = new FormData();
      formData.append("question", faqQuestion);
      formData.append("answer", faqAnswer);
      formData.append("sequence", faqPreference);
      formData.append("type", "FAQ_Page");

      const response = await fetch(`${addFaqPage}`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        return true;
      } else {
        throw new Error("Failed to add FAQ");
      }
    } catch (error) {
      console.error("Error adding FAQ:", error);
      return false;
    }
  }

  const handleAddFaq = async () => {
    if (!faqQuestion) {
      alert("Please fill in all fields");
      return;
    }

    setIsAddingFaq(true);

    const result = await addFaq(faqQuestion, faqAnswer, faqPreference);
    setIsAddingFaq(false);
    if (result) {
      alert("FAQ Added Successfully");
      // console.log(newsStatus);
      window.location.reload();
      onClose();
    } else {
      alert("Error Adding FAQ");
    }
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <div>
      {modalAdd_faqIsOpen && <add_faq onClose={closeAdd_faq} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "auto",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add FAQ</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", padding: "2%" }}
            >
              <form>
                <label class="small mb-1" for="inputFullName">
                  Question
                </label>
                <input
                  class="form-control"
                  id="inputFullName"
                  type="text"
                  value={faqQuestion}
                  onChange={(e) => setFaqQuestion(e.target.value)}
                />
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName" type="text">
                    Answer
                  </label>
                  <textarea
                    style={{ height: '50px' }}
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={faqAnswer}
                    onChange={(e) => setFaqAnswer(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Preference
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="number"
                    min="0"
                    value={faqPreference}
                    onChange={(e) => setFaqPreference(e.target.value)}
                  />
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingaFaq === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAddFaq}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_faq;
