import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_workshop.css";
import Select from "react-select";
import { FormCheck } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import $ from 'jquery';
import Select2 from 'react-select2-wrapper';
import 'select2/dist/css/select2.css';
import "../../Api/apiUrls";
import { API_URL } from "../../Api/apiUrls";

import {
  addAdminWorkshopFAQ,
  addAdminWorkshopSlot,
  addAdminWorkshop,
  UpdateAdminWorkshopFAQ,
  deleteAdminWorkshopFAQ,
  getAdminWorkshopFAQ,
  getAdminWorkshopSlot,
  getAdminWorkshopSlotById,
  UpdateAdminWorkshopSlot,
  getAdminWorkshopSlotByTempId,
  deleteAdminWorkshopSlot,
  getCategories,
  getAdminSubCategories,
  getAdminLocationCity,
  getAdminLocationArea,
  getFAQByTempId,
  getFAQById,
} from "../../Api/apiUrls";

// import { addAdminWorkshop } from "../../Api/apiUrls";
function Add_workshop({ onClose, dateTime }) {
  console.log(dateTime);
  const [modalAdd_workshopIsOpen, setAdd_workshopModalIsOpen] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAddingworkshop, setIsAddingWorkshop] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [showFields2, setShowFields2] = useState(false);
  const [showFields3, setShowFields3] = useState(false);
  const [showFields4, setShowFields4] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [location, setLocation] = useState([]);
  // const [city, setCity] = useState([]);
  const [Question, setQuestion] = useState("");
  const [Answer, setAnswer] = useState("");
  const [newFaqAnswer, setNewFaqAnswer] = useState(Answer);
  const [newFaqQuestion, setNewFaqQuestion] = useState(Question);
  const [isAddingFaq, setIsAddingFaq] = useState(false);
  const [datetime, setDatetime] = useState("");
  const [id, setId] = useState("");
  const [id2, setId2] = useState("");
  const [isUpdatingFaq, setIsUpdatingFaq] = useState(false);
  const [workshopStatus, setWorkshopStatus] = useState("");
  const [therapyChange, setTherapy] = useState("");
  const [outstationChange, setOutstation] = useState("");
  const [onlineChange, setOnline] = useState("");
  const [isAddingBookingSlot, setIsAddingBookingSlot] = useState(false);
  const [bookingDate, setBookingDate] = useState("");
  const [bookingStartTime, setBookingStartTime] = useState("00:00");
  const [bookingEndTime, setBookingEndTime] = useState("00:00");
  const [bookingPrice, setBookingPrice] = useState("");
  const [bookingCapacity, setBookingCapacity] = useState("");
  const [bookingNewDate, setBookingNewDate] = useState("");
  const [bookingNewStartTime, setBookingNewStartTime] = useState("");
  const [bookingNewEndTime, setBookingNewEndTime] = useState("");
  const [bookingNewPrice, setBookingNewPrice] = useState("");
  const [bookingNewCapacity, setBookingNewCapacity] = useState("");
  const [isUpdatingSlot, setIsUpdatingSlot] = useState(false);
  const [workshopName, setWorkshopName] = useState("");
  const [workshopShortDescription, setWorkshopShortDescription] = useState("");
  const [workshopDescription, setWorkshopDescription] = useState("");
  const [workshopKnowledge, setWorkshopKnowledge] = useState("");
  const [workshopWhatYouGet, setWorkshopWhatYouGet] = useState("");
  const [workshopWhatBring, setWorkshopWhatBring] = useState("");
  const [workshopSuitable, setWorkshopSuitable] = useState("");
  const [workshopSeats, setWorkshopSeats] = useState("");
  const [workshopDuration, setWorkshopDuration] = useState("");
  const [workshopPrice, setWorkshopPrice] = useState("");
  const [workshopAddress, setWorkshopAddress] = useState("");
  const [workshopPostalCode, setWorkshopPostalCode] = useState("");
  const [workshopGoogleCode, setWorkshopGoogleCode] = useState("");
  const [workshopEmail, setWorkshopEmail] = useState("");
  const [workshopPhone, setWorkshopPhone] = useState("");
  const [workshopWebsite, setWorkshopWebsite] = useState("");
  const [instructors, setInstructors] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const closeAdd_workshop = () => {
    setAdd_workshopModalIsOpen(false);
  };

  const filter = [{ value: "1", label: "1" }];
  const therapy = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const outsation = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const online = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  function reloadPage() {
    window.location.reload();
  }
  const homepage = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const handleStatusChange = (selectedOption) => {
    setWorkshopStatus(selectedOption.value);
  };
  const handleTherapyChange = (selectedOption) => {
    setTherapy(selectedOption.value);
  };
  const handleOutstationChange = (selectedOption) => {
    setOutstation(selectedOption.value);
  };
  const handleOnlineChange = (selectedOption) => {
    setOnline(selectedOption.value);
  };
  const handleAddClick = (event) => {
    event.preventDefault();
    setShowFields(true);
  };

  const handleCancelClick = () => {
    setShowFields(false);
  };

  const handleAddClick2 = (event) => {
    event.preventDefault();
    setBookingDate(getTodayDate());
    setBookingStartTime("00:00");
    setBookingEndTime("00:00");
    setBookingPrice("");
    setBookingCapacity("");
    setShowFields2(true);
  };

  const handleCancelClick2 = () => {
    setShowFields2(false);
  };

  const handleCancelClick3 = () => {
    setShowFields3(false);
  };
  const handleCancelClick4 = () => {
    setShowFields4(false);
  };

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  // function formatDate(inputDate) {
  //   const date = new Date(inputDate);
  //   const day = date.getDate();
  //   const month = date.getMonth() + 1; // Month is zero-based, so we add 1
  //   const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
  //   return `${padNumber(day)}/${padNumber(month)}/${year}`;
  // }
  const formatStartDate = (startDate) => {
    const date = new Date(startDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    const today = getTodayDate();
    setBookingDate(today);
  }, []);

  // Initialize state variables
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);

  // Fetch categories and subcategories
  useEffect(() => {
    fetch(`${getCategories}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (Array.isArray(data.data.Categories)) {
            // Filter categories by type 'Workshop'
            const workshopCategories = data.data.Categories.filter(
              (category) => category.type === "Workshop"
            );
            setCategories(workshopCategories);
          } else {
            console.error(
              "Categories data is not an array:",
              data.data.Categories
            );
          }
        } else {
          console.error("Error fetching category:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });

    fetch(`${getAdminSubCategories}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (Array.isArray(data.data.SubCategories)) {
            const workshopSubCategories = data.data.SubCategories.filter(
              (subCategory) => subCategory.type === "Workshop"
            );
            setSubCategories(workshopSubCategories);
          } else {
            console.error(
              "Sub Categories data is not an array:",
              data.data.SubCategories
            );
          }
        } else {
          console.error("Error fetching sub categories:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching sub categories:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      const filteredSubCategories = subCategories.filter(
        (subCategory) => subCategory.category.id === selectedCategory.value
      );
      // console.log(subCategories.category);
      setFilteredSubCategories(filteredSubCategories);
      console.log(filteredSubCategories);
    } else {
      setFilteredSubCategories([]);
    }
  }, [selectedCategory, subCategories]);

  // Update selected category state
  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategory(selectedOptions);
    setSelectedCategories([selectedOptions]);
  };

  // Update selected subcategory state
  const handleSubCategoryChange = (selectedOptions) => {
    setSelectedSubCategories([selectedOptions]);
  };

  // Map filtered subcategories to options
  const subCategoryOptions = filteredSubCategories.map((subCategory) => ({
    value: subCategory.id,
    label: subCategory.name,
  }));

  const categoryOptions = categories.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  // Initialize state variables
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [city, setCity] = useState([]); // Add state for city
  const [filteredAreas, setFilteredAreas] = useState([]);

  // Fetch cities and areas
  useEffect(() => {
    fetch(`${getAdminLocationCity}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCity(data.data.LocationCity); // Set fetched cities
        } else {
          console.error("Error fetching city:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching city:", error);
      });

    fetch(`${getAdminLocationArea}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setLocation(data.data.LocationArea); // Initially set all areas
        } else {
          console.error("Error fetching area:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching area:", error);
      });
  }, []);

  // Filter areas based on selected city
  useEffect(() => {
    if (selectedCity && selectedCity.value) {
      // Check if a city is selected
      console.log(selectedCity);
      const filteredAreasResult = location.filter(
        (location) => location.city.id === selectedCity.value
      );
      setFilteredAreas(filteredAreasResult);
    }
  }, [selectedCity, location]);

  // Map filtered areas to options
  const areaOptions = filteredAreas.map((area) => ({
    value: area.id,
    label: area.name,
  }));

  // Event handler for city selection
  const handleCityChange = (selectedOptions) => {
    setSelectedCity(selectedOptions);
    setSelectedCities([selectedOptions]);
  };

  // Define cityOptions
  const cityOptions = city.map((city) => ({
    value: city.id,
    label: city.name,
  }));

  // Define handleAreaChange function
  const handleAreaChange = (selectedOptions) => {
    setSelectedLocation([selectedOptions]);
  };

  // --------------------------------------add  Faqs-------------------------------------------------------------
  const [faq, setFaq] = useState(false);
  const handleAddFAQ = async (event) => {
    try {
      event.preventDefault();

      if (!Question) {
        alert("Please fill in all fields");
        return;
      }

      setIsAddingFaq(true);

      const formData = new FormData();
      formData.append("temp_id", dateTime);
      formData.append("type", "Workshop");
      formData.append("question", Question);
      formData.append("answer", Answer);

      const addResponse = await fetch(addAdminWorkshopFAQ, {
        method: "POST",
        body: formData,
      });

      if (!addResponse.ok) {
        alert("Failed to add FAQ's");
        return;
      }
      const getResponse = await fetch(`${getFAQByTempId}${dateTime}`);

      if (!getResponse.ok) {
        alert("Failed to fetch FAQs");
        return;
      }

      const getData = await getResponse.json();
      const faqData = getData.data.FAQ || [];

      setFaq(faqData);
      setShowFields(false);
      setQuestion("");
      setAnswer("");
    } catch (error) {
      console.error("Error handling FAQ:", error);
    } finally {
      setIsAddingFaq(false);
    }
  };

  // ------------------------------------------get faq by id-----------------------------------------------

  const handleClick3 = (id) => {
    // event.preventDefault();

    fetch(`${getFAQById}${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const bookingFaq = data.data.FAQ;
        console.log("each", bookingFaq);
        // setEachSlot(bookingFaq);
        setNewFaqAnswer(bookingFaq.answer);
        setNewFaqQuestion(bookingFaq.question);
        setDatetime(bookingFaq.temp_id);
        setId(bookingFaq.id);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });

    setShowFields3(true);
  };

  // --------------------------------------------update faqs-----------------------------------------------------------------------------

  const handleUpdateFaq = async (id) => {
    try {
      setIsUpdatingFaq(true);

      const formData = new FormData();
      formData.append("faq_id", id);
      formData.append("temp_id", datetime);
      formData.append("question", newFaqQuestion);
      formData.append("answer", newFaqAnswer);

      const updateResponse = await fetch(UpdateAdminWorkshopFAQ, {
        method: "PUT",
        body: formData,
      });

      if (!updateResponse.ok) {
        alert("Failed to update FAQ");
        return;
      }

      // Trigger get API after update
      const getResponse = await fetch(`${getFAQByTempId}${datetime}`);

      if (!getResponse.ok) {
        alert("Failed to fetch FAQ");
        return;
      }

      const getData = await getResponse.json();
      const updatedFaqData = getData.data.FAQ || [];

      setFaq(updatedFaqData);
      setShowFields3(false);
    } catch (error) {
      console.error("Error updating FAQ:", error);
    } finally {
      setIsUpdatingFaq(false);
    }
  };

  // ----------------------------delete faqs-----------------------------------------------

  const handleDeleteFaq = (id) => {
    fetch(deleteAdminWorkshopFAQ + id, {
      method: "DELETE",
      headers: {
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Check if the delete operation was successful
        if (data.success) {
          // Trigger the get API after successful deletion
          return fetch(getAdminWorkshopFAQ + dateTime);
        } else {
          // Handle the error if the delete operation was not successful
          console.error("Error deleting FAQ:", data.message);
        }
      })
      .then((getResponse) => {
        // Check if the get API response is successful
        if (getResponse && getResponse.ok) {
          return getResponse.json();
        } else {
          throw new Error("Failed to fetch FAQs");
        }
      })
      .then((getData) => {
        // Update the table with the fetched data
        const updatedFaqData = getData.data.FAQ || [];
        setFaq(updatedFaqData);
      })
      .catch((error) => {
        console.error("Error handling FAQ:", error);
      });
  };

  // ------------------------add workshop slot ------------------------------------------------------------
  const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(":");
    const amPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
    return `${formattedHours}:${minutes}`;
  };
  const [bookingSlot, setBookingSlot] = useState([]);
  const handleAddBookingSlot = async (event) => {
    try {
      event.preventDefault();

      if (!bookingDate) {
        alert("Please fill in all fields");
        return;
      }

      setIsAddingBookingSlot(true);

      const formattedStartTime = convertTo12HourFormat(bookingStartTime);
      const formattedEndTime = convertTo12HourFormat(bookingEndTime);

      const formData = new FormData();
      formData.append("date", bookingDate);
      formData.append("startTime", formattedStartTime);
      formData.append("endTime", formattedEndTime);
      formData.append("price", bookingPrice);
      formData.append("temp_id", dateTime);
      formData.append("totalSeats", bookingCapacity);
      formData.append("availableSeats", bookingCapacity);
      formData.append("changed_by", "Admin");

      const addResponse = await fetch(addAdminWorkshopSlot, {
        method: "POST",
        body: formData,
      });

      if (!addResponse.ok) {
        alert("Failed to add Slot");
        return;
      }
      const getResponse = await fetch(getAdminWorkshopSlot + dateTime);

      if (!getResponse.ok) {
        alert("Failed to fetch slots");
        return;
      }

      const getData = await getResponse.json();
      const bookingSlot = getData.data.WorkshopSlot || [];

      setBookingSlot(bookingSlot);
      setShowFields2(false);
      setBookingDate("");
      setBookingStartTime("");
      setBookingEndTime("");
      setBookingPrice("");
      setBookingCapacity("");
    } catch (error) {
      console.error("Error handling FAQ:", error);
    } finally {
      setIsAddingBookingSlot(false);
    }
  };

  // ---------------------------------get workshop slot by id --------------------------------------------------------

  const handleClick4 = (id2) => {
    // event.preventDefault();

    fetch(getAdminWorkshopSlotById + id2)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const bookingEachSlot = data.data.WorkshopSlot;
        setBookingNewDate(bookingEachSlot.date);
        setBookingNewStartTime(bookingEachSlot.startTime);
        setBookingNewEndTime(bookingEachSlot.endTime);
        setBookingNewPrice(bookingEachSlot.price);
        setBookingNewCapacity(bookingEachSlot.totalSeats);
        setDatetime(bookingEachSlot.temp_id);
        setId2(bookingEachSlot.id);
        console.log(bookingEachSlot.id);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });

    setShowFields4(true);
  };

  // -------------------------update wokshop slot ----------------------------------------------------------------

  const handleUpdateWorkshop = async (id2) => {
    try {
      setIsUpdatingSlot(true);

      const formData = new FormData();
      formData.append("WorkshopSlot_id", id2);
      formData.append("temp_id", dateTime);
      formData.append("date", bookingNewDate);
      formData.append("startTime", bookingNewStartTime);
      formData.append("endTime", bookingNewEndTime);
      formData.append("price", bookingNewPrice);
      formData.append("totalSeats", bookingNewCapacity);
      formData.append("changed_by", "Admin");

      const updateResponse = await fetch(UpdateAdminWorkshopSlot, {
        method: "PUT",
        body: formData,
      });

      if (!updateResponse.ok) {
        alert("Failed to update slot");
        return;
      }

      // Trigger get API after update
      const getResponse = await fetch(getAdminWorkshopSlotByTempId + dateTime);

      if (!getResponse.ok) {
        alert("Failed to fetch slot");
        return;
      }

      const getData = await getResponse.json();
      const bookingSlot = getData.data.WorkshopSlot || [];

      setBookingSlot(bookingSlot);
      setShowFields4(false);
    } catch (error) {
      console.error("Error handling slot:", error);
    } finally {
      setIsUpdatingSlot(false);
    }
  };

  // ---------------------------delete workshop slot ------------------------------------

  const handleDeleteWorkshop = (id2) => {
    fetch(deleteAdminWorkshopSlot + id2, {
      method: "DELETE",
      headers: {
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Check if the delete operation was successful
        if (data.success) {
          // Trigger the get API after successful deletion
          return fetch(getAdminWorkshopSlotByTempId + dateTime);
        } else {
          // Handle the error if the delete operation was not successful
          alert(data.message);
        }
      })
      .then((getResponse) => {
        // Check if the get API response is successful
        if (getResponse && getResponse.ok) {
          return getResponse.json();
        } else {
          throw new Error("Failed to fetch slot");
        }
      })
      .then((getData) => {
        const bookingSlot = getData.data.WorkshopSlot || [];
        setBookingSlot(bookingSlot);
      })
      .catch((error) => {
        console.error("Error handling slot:", error);
      });
  };

  // ---------------------------instructor----------------------------------------------

  const [selectedInstructor, setSelectedInstructor] = useState(null);
  useEffect(() => {
    fetch(`${API_URL}/user/get-instructor/`)
        .then(response => response.json())
        .then(data => {
            const options = data.data.users.map(user => ({
                id: user.id,
                text: user.name,
                image: `${API_URL}/${user.profile_image}`,
            }));
            setInstructors(options);
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error fetching instructors:', error);
            setIsLoading(false);
        });
}, []);

  // const handleInputChange = (newValue) => {
  //   setSearchValue(newValue);
  //   filterOptions(newValue);
  // };

  // const filterOptions = (inputValue) => {
  //   const filtered = instructors.filter((option) =>
  //     option.label.toLowerCase().includes(inputValue.toLowerCase())
  //   );
  //   setFilteredOptions(filtered);
  //   console.log(filtered);
  // };

  // const [selectedInstructor, setSelectedInstructor] = useState(null);

  // const handleInstructorChange = (selectedOption) => {
  //   setSelectedInstructor([selectedOption]);
  // };

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

      // Helper functions to format the display of options
      const formatOption = (state) => {
        if (!state.id) return state.text; // Default text

        const instructor = instructors.find(inst => inst.id == state.id);
        if (!instructor) return state.text;

        return $(
            `<span><img src="${instructor.image}" class="img-flag" style="width:20px; margin-right: 10px;" />${state.text}</span>`
        );
    };

    const formatOptionSelection = (state) => {
        if (!state.id) return state.text; // Default text

        const instructor = instructors.find(inst => inst.id == state.id);
        if (!instructor) return state.text;

        return $(
            `<span><img src="${instructor.image}" class="img-flag" style="width:20px; margin-right: 10px;" />${state.text}</span>`
        );
    };

  // ------------------------------add workshop -----------------------------------------------------
  const OwnerId = localStorage.getItem("user_id");
  async function addWorkshop(
    workshopName,
    workshopShortDescription,
    workshopDescription,
    workshopKnowledge,
    workshopWhatYouGet,
    workshopWhatBring,
    workshopSuitable,
    workshopSeats,
    workshopDuration,
    workshopPrice,
    therapyChange,
    outstationChange,
    onlineChange,
    workshopAddress,
    workshopPostalCode,
    workshopGoogleCode,
    workshopEmail,
    workshopPhone,
    workshopWebsite,
    workshopStatus,
    selectedLocation,
    selectedCategories,
    selectedSubCategories,
    selectedCities,
    selectedInstructor
  ) {
    try {
      const formData = new FormData();
      formData.append("workshopInstructor", OwnerId);
      formData.append("temp_id", dateTime);
      formData.append("name", workshopName);
      formData.append("short_description", workshopShortDescription);
      formData.append("description", workshopDescription);
      formData.append("knowledge_require", workshopKnowledge);
      formData.append("what_you_will_get", workshopWhatYouGet);
      formData.append("what_to_bring", workshopWhatBring);
      formData.append("suitable_for", workshopSuitable);
      formData.append("category", selectedCategories[0].value);
      formData.append("subcategory", selectedSubCategories[0].value);
      formData.append("total_seats", workshopSeats);
      formData.append("duration_in_hour", workshopDuration);
      formData.append("price", workshopPrice);
      formData.append("therapy", therapyChange);
      formData.append("outstation", outstationChange);
      formData.append("online", onlineChange);
      formData.append("address", workshopAddress);
      formData.append("city", selectedCities[0].value);
      if (selectedLocation && selectedLocation.length > 0) {
        formData.append("area", selectedLocation[0].value);
      } else {
        console.error("selectedLocation is undefined or empty");
      }
      formData.append("pincode", workshopPostalCode);
      formData.append("google_location_link", workshopGoogleCode);
      formData.append("email", workshopEmail);
      formData.append("mobileNumber", workshopPhone);
      formData.append("website", workshopWebsite);
      formData.append("status", workshopStatus);
      formData.append("workshopInstructor", selectedInstructor);
      formData.append("changed_by", "Admin");

      const response = await fetch(addAdminWorkshop, {
        method: "POST",
        body: formData,
      });

      console.log(response);
      if (response.ok) {
        return true;
      } else {
        throw new Error("Failed to add workshop");
      }
    } catch (error) {
      console.error("Error adding workshop:", error);
      return false;
    }
  }

  const handleAddWorkshop = async () => {
    let missingField = [];

    if (!workshopName) missingField.push("workshop name");
    if (!workshopPrice) missingField.push("workshop price");
    if (!workshopShortDescription) missingField.push("workshop short description");
    if (!workshopDescription) missingField.push("workshop description");
    if (!selectedCategories || !selectedCategories[0] || !selectedCategories[0].value) missingField.push("workshop category");
    if (!selectedSubCategories || !selectedSubCategories[0] || !selectedSubCategories[0].value) missingField.push("workshop subcategory");
    if (!selectedCities || !selectedCities[0] || !selectedCities[0].value) missingField.push("workshop city");
    if (!selectedLocation || !selectedLocation[0] || !selectedLocation[0].value) missingField.push("workshop area");
    if (!workshopStatus) missingField.push("workshop status");
    if (!workshopSeats) missingField.push("workshop seats");
    if (!workshopDuration) missingField.push("workshop duration");
    if (!therapyChange) missingField.push("therapy ");
    if (!outstationChange) missingField.push("outstation ");
    if (!onlineChange) missingField.push(" workshop Online ");
    if (!selectedInstructor || !selectedInstructor || !selectedInstructor) missingField.push("workshop instructor");

    if (missingField.length > 0) {
      alert(`${missingField.join(", ")} is required.`);
      return;
    }

    setIsAddingWorkshop(true);

    const result = await addWorkshop(
      workshopName,
      workshopShortDescription,
      workshopDescription,
      workshopKnowledge,
      workshopWhatYouGet,
      workshopWhatBring,
      workshopSuitable,
      workshopSeats,
      workshopDuration,
      workshopPrice,
      therapyChange,
      outstationChange,
      onlineChange,
      workshopAddress,
      workshopPostalCode,
      workshopGoogleCode,
      workshopEmail,
      workshopPhone,
      workshopWebsite,
      workshopStatus,
      selectedLocation,
      selectedCategories,
      selectedSubCategories,
      selectedCities,
      selectedInstructor
    );

    setIsAddingWorkshop(false);
    console.log(result);
    if (result) {
      alert("Workshop Added Successfully");
      window.location.reload();

      onClose(); // Close the modal
    } else {
      alert("Error Adding workshop");
    }
  };

  return (
    <div>
      {modalAdd_workshopIsOpen && <add_workshop onClose={closeAdd_workshop} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              // overflowY: "scroll",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add Workshop</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Title of Workshop{" "}
                    <font color="red" style={{ fontSize: "10px" }}>
                      * Required
                    </font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopName}
                    onChange={(e) => setWorkshopName(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Short Description<font color="red" style={{ fontSize: "10px" }}>
                      * required
                    </font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopShortDescription}
                    onChange={(e) =>
                      setWorkshopShortDescription(e.target.value)
                    }
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description<font color="red" style={{ fontSize: "10px" }}>
                      * required
                    </font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopDescription}
                    onChange={(e) => setWorkshopDescription(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Knowledge Required
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopKnowledge}
                    onChange={(e) => setWorkshopKnowledge(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    What you'll get
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopWhatYouGet}
                    onChange={(e) => setWorkshopWhatYouGet(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    What to bring
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopWhatBring}
                    onChange={(e) => setWorkshopWhatBring(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Suitable for
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=" "
                    value={workshopSuitable}
                    onChange={(e) => setWorkshopSuitable(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Category <font color="red" style={{ fontSize: "10px" }}>
                      * required
                    </font>
                  </label>

                  <Select
                    options={categoryOptions}
                    value={
                      selectedCategories.length > 0
                        ? selectedCategories[0]
                        : null
                    }
                    onChange={handleCategoryChange}
                  ></Select>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Sub-Category <font color="red" style={{ fontSize: "10px" }}>
                      * required
                    </font>
                  </label>

                  <Select
                    options={subCategoryOptions}
                    value={
                      selectedSubCategories.length > 0
                        ? selectedSubCategories[0]
                        : null
                    }
                    onChange={handleSubCategoryChange}
                  ></Select>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Max People <font color="red" style={{ fontSize: "10px" }}>
                      * required
                    </font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="number"
                    placeholder=""
                    value={workshopSeats}
                    onChange={(e) => setWorkshopSeats(e.target.value)}
                  />
                  <div class="form-group">
                    <label class="small mb-1" for="inputFullName">
                      Duration in Hrs. <font color="red" style={{ fontSize: "10px" }}>
                        * required
                      </font>
                    </label>
                    <input
                      class="form-control"
                      id="inputFullName"
                      type="number"
                      placeholder=""
                      value={workshopDuration}
                      onChange={(e) => setWorkshopDuration(e.target.value)}
                    />

                    <div class="valid-feedback">Thank you!</div>
                  </div>

                  <div class="form-group">
                    <label class="small mb-1" for="inputFullName">
                      Fees
                      <font color="red" style={{ fontSize: "10px" }}>
                        * Required
                      </font>
                    </label>
                    <input
                      class="form-control"
                      id="inputFullName"
                      type="number"
                      placeholder=""
                      value={workshopPrice}
                      onChange={(e) => setWorkshopPrice(e.target.value)}
                    />

                    <div class="valid-feedback">Thank you!</div>
                  </div>
                  <div class="form-group">
                    <label class="small mb-1" for="inputFullName">
                      Therapy<font color="red" style={{ fontSize: "10px" }}>
                        * required
                      </font>
                    </label>
                    <div class="child ml-2">
                      <Select
                        onChange={handleTherapyChange}
                        options={therapy}
                      ></Select>
                    </div>

                    <div class="valid-feedback">Thank you!</div>
                  </div>
                  <div class="form-group">
                    <label class="small mb-1" for="inputFullName">
                      Outstation<font color="red" style={{ fontSize: "10px" }}>
                        *  required
                      </font>
                    </label>
                    <div class="child ml-2">
                      <Select
                        onChange={handleOutstationChange}
                        options={outsation}
                      ></Select>
                    </div>

                    <div class="valid-feedback">Thank you!</div>
                  </div>

                  <div class="form-group">
                    <label class="small mb-1" for="inputFullName">
                      Address Line1
                    </label>
                    <input
                      class="form-control"
                      id="inputFullName"
                      type="text"
                      placeholder=""
                      value={workshopAddress}
                      onChange={(e) => setWorkshopAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Select City<font color="red" style={{ fontSize: "10px" }}>
                      * required
                    </font>
                  </label>
                  <Select
                    options={cityOptions}
                    value={selectedCities.length > 0 ? selectedCities[0] : null}
                    onChange={handleCityChange}
                  ></Select>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Select Area <font color="red" style={{ fontSize: "10px" }}>
                      * required
                    </font>
                  </label>
                  <Select
                    options={areaOptions}
                    value={
                      selectedLocation.length > 0 ? selectedLocation[0] : null
                    }
                    onChange={handleAreaChange}
                  ></Select>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Postal Code
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="number"
                    placeholder=""
                    value={workshopPostalCode}
                    onChange={(e) => setWorkshopPostalCode(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Google Maps Link
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopGoogleCode}
                    onChange={(e) => setWorkshopGoogleCode(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Email
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="email"
                    placeholder=""
                    value={workshopEmail}
                    onChange={(e) => setWorkshopEmail(e.target.value)}
                  />
                  {!workshopEmail.includes("@") && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      Please enter a valid email address.
                    </p>
                  )}

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Phone
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="number"
                    placeholder=""
                    value={workshopPhone}
                    onChange={(e) => setWorkshopPhone(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Website
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={workshopWebsite}
                    onChange={(e) => setWorkshopWebsite(e.target.value)}
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>
                <div>
                  <div className="form-group">
                    <label className="small mb-1" htmlFor="inputFullName">
                      FAQ's
                    </label>
                    <button
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        borderRadius: "4px",
                        backgroundColor: "#000",
                        color: "#fff",
                      }}
                      onClick={handleAddClick}
                    >
                      + Add
                    </button>
                  </div>
                  {showFields && (
                    <>
                      <div
                        class="col-md-12"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="form-group col-md-6">
                          <label className="small mb-1" htmlFor="inputFullName">
                            Enter Question
                          </label>
                          <input
                            className="form-control"
                            id="inputFullName"
                            type="text"
                            value={Question}
                            onChange={(e) => setQuestion(e.target.value)}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="small mb-1" htmlFor="inputFullName">
                            Enter Answer
                          </label>
                          <input
                            className="form-control"
                            id="inputFullName"
                            type="text"
                            value={Answer}
                            onChange={(e) => setAnswer(e.target.value)}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", marginBottom: "20px" }}>
                        <button
                          type="submit"
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            borderRadius: "4px",
                            backgroundColor: "#000",
                            color: "#fff",
                            border: "none",
                            padding: "5px 20px",
                          }}
                          onClick={handleAddFAQ}
                        >
                          Add
                        </button>
                        <button
                          type="button"
                          onClick={handleCancelClick}
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            borderRadius: "4px",
                            backgroundColor: "lightgrey",
                            color: "#000",
                            border: "none",
                            padding: "5px 20px",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <div className="table-responsive">
                  {Array.isArray(faq) && faq.length > 0 ? (
                    <table
                      className="table table-striped"
                      style={{ padding: "0rem" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">Sr.No</th>
                          <th scope="col">Question</th>
                          <th scope="col">Answer</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {faq.map((faqItem, index) => (
                          <tr key={faqItem.id}>
                            <th scope="row">{index + 1}</th>
                            <td>
                              <b>{faqItem.question || "-"}</b>
                            </td>
                            <td>
                              <b>{faqItem.answer || "-"}</b>
                            </td>
                            <td>
                              <div className="social-links mt-2">
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleClick3(faqItem.id)}
                                >
                                  <i className="bi bi-pencil"></i>
                                </a>
                                &nbsp;&nbsp;
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleDeleteFaq(faqItem.id)}
                                >
                                  <i className="bi bi-trash3"></i>
                                </a>
                                &nbsp;
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <h3 style={{ textAlign: "center" }}></h3>
                  )}
                </div>

                {showFields3 && (
                  <div>
                    <>
                      <div>
                        <div
                          class="col-md-12"
                          style={{ display: "flex", paddingLeft: "0px" }}
                        >
                          <div
                            class="form-group col-md-6"
                            style={{ paddingLeft: "0px" }}
                          >
                            <label class="small mb-1" for="inputFullName">
                              Question<font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="text"
                              // value={eachSlot.startDate}
                              value={newFaqQuestion}
                              onChange={(e) =>
                                setNewFaqQuestion(e.target.value)
                              }
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="small mb-1" for="inputFullName">
                              Answer <font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="text"
                              // value={eachSlot.startTime}
                              value={newFaqAnswer}
                              onChange={(e) => setNewFaqAnswer(e.target.value)}
                            />
                          </div>
                        </div>

                        <div style={{ display: "flex", marginBottom: "20px" }}>
                          <button
                            type="button"
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              borderRadius: "4px",
                              backgroundColor: "#000",
                              color: "#fff",
                              border: "none",
                              padding: "5px 20px",
                            }}
                            onClick={() =>
                              handleUpdateFaq(id, newFaqAnswer, newFaqQuestion)
                            }
                          >
                            Update
                          </button>
                          <button
                            type="button"
                            onClick={handleCancelClick3}
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              borderRadius: "4px",
                              backgroundColor: "lightgrey",
                              color: "#000",
                              border: "none",
                              padding: "5px 20px",
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  </div>
                )}

                {/* <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Workshop Slots
                  </label>
                  <button
                    style={{
                      marginLeft: "10px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      backgroundColor: "#000",
                      color: "#fff",
                    }}
                    onClick={handleAddClick2}
                  >
                    + Add
                  </button>
                </div> */}
                {showFields2 && (
                  <>
                    <div
                      class="col-md-12"
                      style={{ display: "flex", paddingLeft: "0px" }}
                    >
                      <div
                        class="form-group col-md-6"
                        style={{ paddingLeft: "0px" }}
                      >
                        <label class="small mb-1" for="inputFullName">
                          Select Date<font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="date"
                          value={bookingDate}
                          min={bookingDate}
                          onChange={(e) => setBookingDate(e.target.value)}
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="small mb-1" for="inputFullName">
                          Select Start time <font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="time"
                          value={bookingStartTime}
                          onChange={(e) => setBookingStartTime(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      class="col-md-12"
                      style={{ display: "flex", paddingLeft: "0px" }}
                    >
                      <div
                        class="form-group col-md-6"
                        style={{ paddingLeft: "0px" }}
                      >
                        <label class="small mb-1" for="inputFullName">
                          Select End Time<font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="time"
                          value={bookingEndTime}
                          onChange={(e) => setBookingEndTime(e.target.value)}
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="small mb-1" for="inputFullName">
                          Enter Price <font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="number"
                          value={bookingPrice}
                          onChange={(e) => setBookingPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label class="small mb-1" for="inputFullName">
                        Enter Seating Capacity<font color="red"></font>
                      </label>
                      <input
                        class="form-control"
                        id="inputFullName"
                        type="number"
                        value={bookingCapacity}
                        onChange={(e) => setBookingCapacity(e.target.value)}
                      />
                    </div>
                    <div style={{ display: "flex", marginBottom: "20px" }}>
                      <button
                        type="button"
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          borderRadius: "4px",
                          backgroundColor: "#000",
                          color: "#fff",
                          border: "none",
                          padding: "5px 20px",
                        }}
                        onClick={handleAddBookingSlot}
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        onClick={handleCancelClick2}
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          borderRadius: "4px",
                          backgroundColor: "lightgrey",
                          color: "#000",
                          border: "none",
                          padding: "5px 20px",
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}

                <div className="table-responsive">
                  {Array.isArray(bookingSlot) && bookingSlot.length > 0 ? (
                    <table
                      className="table table-striped"
                      style={{ padding: "0rem" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">Sr.No</th>
                          <th scope="col">Start Date</th>
                          <th scope="col">Start Time</th>
                          <th scope="col">End Time</th>
                          <th scope="col">Price</th>
                          <th scope="col">Seating Capacity</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingSlot.map((bookingSlot, index) => (
                          <tr key={bookingSlot.id}>
                            <th scope="row">{index + 1}</th>
                            <td>
                              <b>
                                {" "}
                                {bookingSlot.date
                                  ? formatStartDate(bookingSlot.date)
                                  : "---"}
                              </b>
                            </td>
                            <td>
                              <b> {bookingSlot.startTime}</b>
                              <br />
                            </td>
                            <td>
                              <b>{bookingSlot.endTime}</b>
                              <br />
                            </td>
                            <td>
                              <b>{bookingSlot.price || "---"}</b>
                              <br />
                            </td>
                            <td>
                              <b>{bookingSlot.totalSeats || "---"}</b>
                              <br />
                            </td>
                            <td>
                              <div className="social-links mt-2">
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleClick4(bookingSlot.id)}
                                >
                                  <i className="bi bi-pencil"></i>
                                </a>
                                &nbsp;&nbsp;
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleDeleteWorkshop(bookingSlot.id)
                                  }
                                >
                                  <i className="bi bi-trash3"></i>
                                </a>
                                &nbsp;
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <h3 style={{ textAlign: "center" }}></h3>
                  )}
                </div>

                {showFields4 && (
                  <div>
                    <>
                      <div>
                        <div
                          class="col-md-12"
                          style={{ display: "flex", paddingLeft: "0px" }}
                        >
                          <div
                            class="form-group col-md-6"
                            style={{ paddingLeft: "0px" }}
                          >
                            <label class="small mb-1" for="inputFullName">
                              Select Date<font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="date"
                              // value={eachSlot.startDate}
                              value={bookingNewDate}
                              min={bookingDate}
                              onChange={(e) =>
                                setBookingNewDate(e.target.value)
                              }
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="small mb-1" for="inputFullName">
                              Select Start time <font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="time"
                              // value={eachSlot.startTime}
                              value={bookingNewStartTime}
                              onChange={(e) =>
                                setBookingNewStartTime(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div
                          class="col-md-12"
                          style={{ display: "flex", paddingLeft: "0px" }}
                        >
                          <div
                            class="form-group col-md-6"
                            style={{ paddingLeft: "0px" }}
                          >
                            <label class="small mb-1" for="inputFullName">
                              Select End Time<font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="time"
                              // value={eachSlot.endTime}
                              value={bookingNewEndTime}
                              onChange={(e) =>
                                setBookingNewEndTime(e.target.value)
                              }
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="small mb-1" for="inputFullName">
                              Enter Price <font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="number"
                              // value={eachSlot.price}
                              value={bookingNewPrice}
                              onChange={(e) =>
                                setBookingNewPrice(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <label class="small mb-1" for="inputFullName">
                            Enter Seating Capacity<font color="red"></font>
                          </label>
                          <input
                            class="form-control"
                            id="inputFullName"
                            type="number"
                            // value={eachSlot.capacity}
                            value={bookingNewCapacity}
                            onChange={(e) =>
                              setBookingNewCapacity(e.target.value)
                            }
                          />
                        </div>
                        <div style={{ display: "flex", marginBottom: "20px" }}>
                          <button
                            type="button"
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              borderRadius: "4px",
                              backgroundColor: "#000",
                              color: "#fff",
                              border: "none",
                              padding: "5px 20px",
                            }}
                            // onClick={handleeUpdate}
                            onClick={() =>
                              handleUpdateWorkshop(
                                id2,
                                bookingNewDate,
                                bookingNewStartTime,
                                bookingNewEndTime,
                                bookingNewPrice,
                                bookingNewCapacity
                              )
                            }
                          >
                            Update
                          </button>
                          <button
                            type="button"
                            onClick={handleCancelClick4}
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              borderRadius: "4px",
                              backgroundColor: "lightgrey",
                              color: "#000",
                              border: "none",
                              padding: "5px 20px",
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  </div>
                )}

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status<font color="red" style={{ fontSize: "10px" }}>
                      *  required
                    </font>
                  </label>
                  <div class="child ml-2">
                    <Select
                      onChange={handleStatusChange}
                      options={status}
                    ></Select>
                  </div>
                </div>
                {/* <div className="form-group">
                  <label className="small mb-1" htmlFor="inputFullName">
                    Select Instructor<font color="red" style={{ fontSize: "10px" }}>
                    * required
                    </font>
                  </label>
                  <Select
                    options={instructors}
                    // options={filteredOptions}
                    onInputChange={handleInputChange}
                    value={selectedInstructor} // value prop for controlled component
                    onChange={handleInstructorChange} // handle change function
                    getOptionLabel={(option) => (
                      <div>
                        <img
                          src={option.image}
                          style={{ width: "30px", marginRight: "10px" }}
                        />
                        {option.label}
                      </div>
                    )}
                    getOptionValue={(option) => option.value}
                    isSearchable
                  />
                </div> */}

                <div className="form-group">
                  <label className="small mb-1" htmlFor="instructorSelect">
                    Select Instructor <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                  </label>
                  {!isLoading ? (
                    <Select2
                      id="instructorSelect"
                      data={instructors}
                      value={selectedInstructor}
                      onChange={(e) => setSelectedInstructor(e.target.value)}
                      options={{
                        placeholder: 'Select an instructor',
                        allowClear: true,
                        templateResult: formatOption,
                        templateSelection: formatOptionSelection,
                      }}
                      style={{ width: '100%' }}
                    />
                  ) : (
                    <p>Loading instructors...</p>
                  )}
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Online<font color="red" style={{ fontSize: "10px" }}>
                      * required
                    </font>
                  </label>
                  <div class="child ml-2">
                    <Select
                      onChange={handleOnlineChange}
                      options={online}
                    ></Select>
                  </div>

                  <div class="valid-feedback">Thank you!</div>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  // reloadPage();
                }}
              >
                Close
              </button>
              {isAddingworkshop === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAddWorkshop}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_workshop;
