// export const API_URL = "http://3.110.1.182:8000";
// export const API_URL_NEW = "http://3.110.1.182/";
// export const API_URL_WS = "ws://3.110.1.182:8000/ws";
export const API_URL = "https://artiquity.in/api";
export const API_URL_NEW = "https://artiquity.in/";
export const API_URL_WS = "wss://artiquity.in/ws/";
// ########################ADMIN
// -------------------(START)ADMIN ADD API-----------------------------------//
export const addAboutUs = `${API_URL}/about_us/add-about-us/`;
export const addBanner = `${API_URL}/banner/add-banner/`;
export const addBlog = `${API_URL}/blogs_and_news/add-blog_and_news/`;
export const addCategories = `${API_URL}/categories/add-categories/`;
export const addLocationCity = `${API_URL}/location_city/add-location_city/`;
export const addLocationArea = `${API_URL}/location_area/add-location_area/`;
export const addMedia = `${API_URL}/venue_images/add-venue-images/`;
export const addAdminNews = `${API_URL}/blogs_and_news/add-blog_and_news/`;
export const addAdminPackage = `${API_URL}/package/add-package/`;
export const addAdminPages = `${API_URL}/pages/add-pages/`;
export const addAdminReviewRating = `${API_URL}/review/add-review/`;
export const addAdminSubCategories = `${API_URL}/sub_categories/add-sub_categories/`;

// --------------------Venue---------------------------//
export const addAdminVenueFAQ = `${API_URL}/faq/add-faq/`;
export const addAdminVenueSlot = `${API_URL}/booking_slot/add-booking_slot/`;
export const addAdminVenue = `${API_URL}/venue/add-venue/`;
//-----------------------------------------------------//

// --------------------Workshop------------------------//
export const addAdminWorkshopFAQ = `${API_URL}/faq/add-faq/`;
export const addAdminWorkshopSlot = `${API_URL}/workshop_slot/add-workshop_slot/`;
export const addAdminWorkshop = `${API_URL}/workshop/add-workshop/`;
//-----------------------------------------------------//

export const addAdminHappy = `${API_URL}/happy_customers/add-happy-customers/`;
export const addAdminMessageChat = `${API_URL}/messages_chat/add-messages-chat/`;

// -------------------(END)ADMIN ADD API-----------------------------------//

// -------------------(START)ADMIN DELETE API------------------------------//

export const deleteAdminBanner = `${API_URL}/banner/delete-banner/?Banner_id=`;
export const deleteAdminBlog = `${API_URL}/blogs_and_news/delete-blog_and_news/?blog_and_news_id=`;
export const deleteAdminCategories = `${API_URL}/categories/delete-categories/?categories_id=`;
export const deleteAdminCity = `${API_URL}/location_city/delete-location_city/?LocationCity_id=`;
export const deleteAdminCustomer = `${API_URL}/user/report-user/?user_id=`;
export const deleteAdminInstructor = `${API_URL}/user/report-user/?user_id=`;
export const deleteAdminLocation = `${API_URL}/location_area/delete-location_area/?LocationArea_id=`;
export const deleteAdminNews = `${API_URL}/blogs_and_news/delete-blog_and_news/?blog_and_news_id=`;
export const deleteAdminPackages = `${API_URL}/package/delete-package/?Package_id=`;
export const deleteAdminPages = `${API_URL}/pages/delete-pages/?Pages_id=`;
export const deleteAdminReviewRating = `${API_URL}/review/delete-review/?Review_id=`;
export const deleteAdminSubCategory = `${API_URL}/sub_categories/delete-sub_categories/?SubCategories_id=`;
export const deleteAdminVenueOwner = `${API_URL}/venue/delete-venue/?Venue_id=`;
export const deleteAdminWorkshop = `${API_URL}/workshop/delete-workshop/?Workshop_id=`;
export const deleteAdminHappy = `${API_URL}/happy_customers/delete-happy-customers/?happy_customers_id=`;
export const deleteAdminWorkshopFAQ = `${API_URL}/faq/delete-faq/?FAQ_id=`;
export const deleteAdminWorkshopSlot = `${API_URL}/workshop_slot/delete-workshop_slot/?WorkshopSlot_id=`;
export const deleteAdminVenueFAQ = `${API_URL}/faq/delete-faq/?FAQ_id=`;
export const deleteAdminVenueSlot = `${API_URL}/booking_slot/delete-booking_slot/?BookingSlot_id=`;
export const deleteAdminVenue = `${API_URL}/venue/delete-venue/?Venue_id=`;
//--------------------(END)ADMIN DELETE API--------------------------------//

//--------------------MESSAGE REPLY ADD API (ADMIN PANEL)--------------------//

export const deleteAdminMessageReply = `${API_URL}/messages_chat/add-messages-chat/`;

// ------------------(START)ADMIN UPDATE API-------------------------------//

export const UpdateAdminBanner = `${API_URL}/banner/update-banner/`;
export const UpdateAdminBlog = `${API_URL}/blogs_and_news/update-blog_and_news/`;
export const UpdateAdminCategories = `${API_URL}/categories/update-categories/`;
export const UpdateAdminCity = `${API_URL}/location_city/update-location_city/`;
export const UpdateAdminArea = `${API_URL}/location_area/update-location_area/`;
export const UpdateAdminNews = `${API_URL}/blogs_and_news/update-blog_and_news/`;
export const UpdateAdminPackages = `${API_URL}/package/update-package/`;
export const UpdateAdminPages = `${API_URL}/pages/update-pages/`;
export const UpdateAdmin = `${API_URL}/banner/update-banner/`;
export const UpdateAdminSubCategories = `${API_URL}/sub_categories/update-sub_categories/`;
export const UpdateAdminToggleCategory = `${API_URL}/categories/update-toggle-categories/`;
export const UpdateAdminToggleSubCategory = `${API_URL}/sub_categories/update-toggle-sub-categories/`;
//----------------------Venue----------------------------------//
export const UpdateAdminVenueFAQ = `${API_URL}/faq/update-faq/`;
export const UpdateAdminVenueSlot = `${API_URL}/booking_slot/update-booking_slot/`;
export const UpdateAdminVenue = `${API_URL}/venue/update-venue/`;
export const UpdateAdminVenueToggle = `${API_URL}/venue/update-venue-toggles/`;
// ---------------------Workshop-------------------------------//
export const UpdateAdminWorkshopFAQ = `${API_URL}/faq/update-faq/`;
export const UpdateAdminWorkshopSlot = `${API_URL}/workshop_slot/update-workshop_slot/`;
export const UpdateAdminWorkshop = `${API_URL}/workshop/update-workshop/`;
export const UpdateAdminWorkshopToggle = `${API_URL}/workshop/update-workshop-toggles/`;
//---------------------(START)ADMIN GET API----------------------//

export const getAdminMessageReply = `${API_URL}/messages_chat/get-messages-chat-by-message-id/?MessagesChat_id=`;
export const getAdminMessage = `${API_URL}/messages_chat/get-messages-chat-by-message-id/?MessagesChat_id=`;
// ---------------------WORKSHOP------------------------//

export const getAdminWorkshopFAQ = `${API_URL}/faq/get-faq_by_temp_id/?temp_id=`;
export const getAdminWorkshopSlot = `${API_URL}/workshop_slot/get-booking-by-tempId/?temp_id=`;
export const getAdminWorkshopSlotById = `${API_URL}/workshop_slot/get-workshop_slot_by_id/?WorkshopSlot_id=`;
export const getAdminWorkshopSlotByTempId = `${API_URL}/workshop_slot/get-booking-by-tempId/?temp_id=`;
// ---------------------VENUE---------------------------//
export const getAdminVenueSlotById = `${API_URL}/booking_slot/get-booking_slot_by_id/?BookingSlot_id=`;
export const getAdminVenueCategory = `${API_URL}/categories/get-categories/`;
export const getAdminVenueCity = `${API_URL}/location_city/get-location_city/`;
export const getAdminVenueArea = `${API_URL}/location_area/get-location_area/`;
export const getAdminVenueFAQByTempId = `${API_URL}/faq/get-faq_by_temp_id/?temp_id=`;
export const getAdminVenueFAQById = `${API_URL}/faq/get-faq_by_id/?FAQ_id=`;
export const getAdminVenueSlotByTempID = `${API_URL}/booking_slot/get-booking_by_temp_id/?temp_id=`;
export const getAdminVenueSlotByID = `${API_URL}/booking_slot/get-booking_slot_by_id/?BookingSlot_id=`;
export const getAdminAboutUs = `${API_URL}/about_us/get-about-us/ `;
// ----------------------------------------------------------------//
export const getAdminAppointment = `${API_URL}/appointment/get-appointment/ `;
export const getAdminAssignedPackages = `${API_URL}/package_management/get-package_management/ `;
export const getAdminBanner = `${API_URL}/banner/get-banner/ `;
export const getAdminBlogs = `${API_URL}/blogs_and_news/get-blogs/ `;
export const getAdminCategories = `${API_URL}/categories/get-categories/`;
export const getAdminCustomer = `${API_URL}/user/get-users/`;
export const getAdminCustomerPayout = `${API_URL}/customer_pay_out/get-customer-payout/`;
export const getAdminHappy = `${API_URL}/happy_customers/get-happy-customers/`;
export const getAdminInstructor = `${API_URL}/user/get-instructor/`;
export const getAdminInstructorPayout = `${API_URL}/instructor_or_owner_pay_out/get-instructor-or-owner-payout/`;
export const getAdminLocationCity = `${API_URL}/location_city/get-location_city/`;
export const getAdminLocationArea = `${API_URL}/location_area/get-location_area/`;
export const getAdminMessages = `${API_URL}/messages/get-messages/`;
export const getAdminNews = `${API_URL}/blogs_and_news/get-news/`;
export const getAdminPackages = `${API_URL}/package/get-package/`;
export const getAdminPages = `${API_URL}/pages/get-pages/`;
export const getAdminQuestion = `${API_URL}/questions/get-questions/`;
export const getAdminReviewRating = `${API_URL}/review/get-review_added_by_admin/`;
export const getAdminReview = `${API_URL}/review/get-review_added_by_non_admin/`;
export const getAdminSubCategories = `${API_URL}/sub_categories/get-sub_categories/`;
export const getAdminSubscribers = `${API_URL}/news_letter/get-news-letter/`;
export const getAdminTransaction = `${API_URL}/transaction/get-transaction/`;
export const getAdminVenueOwner = `${API_URL}/user/get-venue_owner/ `;
export const getAdminVenue = `${API_URL}/venue/get-venue/`;
export const getAdminVenueProfile = `${API_URL}/user/get-user_by_id/?user_id=`;
export const getAdminWorkshop = `${API_URL}/workshop/get-workshop/`;

// ####################CUSTOMER##################################
// -------------------(START)CUSTOMER ADD API-----------------------------------//
export const addCustomerReview = `${API_URL}/review/add-review-by-appointment-id/`;

export const addCustomerMessages = `${API_URL}/messages_chat/add-messages-chat/`;
// export const addCustomerReview = `${API_URL}/review/add-review-by-appointment-id/`;
// export const addCustomerReview = `${API_URL}/review/add-review-by-appointment-id/`;

// -------------------(START)CUSTOMER UPDATE  API-----------------------------------//
export const updateCustomerCancelForm = `${API_URL}/appointment/update-appointment/`;
export const updateCustomerUserViewProfile = `${API_URL}/user/update-user/`;
// -------------------(START) CUSTOMER  Get API-----------------------------------//
export const getQuestionChatId = `${API_URL}/questions_chat/get-questions-chat-by-question-id/?QuestionsChat_id=`;
export const getCustomerMessageChatId = `${API_URL}/messages_chat/get-messages-chat-by-message-id/?MessagesChat_id=`;
export const getCustomerAppointmentId = `${API_URL}/appointment/get-appointment_by_user_id/?user_id=`;
export const getCustomerSentMessages = `${API_URL}/messages/get-sent_messages_by_user_id/?user_id=`;
export const getCustomerQuestion = `${API_URL}/questions/get-questions_by_sender_id/?sender_id=`;
export const getCustomerReview = `${API_URL}/review/get-review_by_user_id/?user_id=`;
export const getCustomerSave = `${API_URL}/saved_blogs_and_news/get-saved-blogs-and-news_by_user_id/?user_id=`;
export const getCustomerUserViewProfile = `${API_URL}/user/get-user_by_id/?user_id=`;
export const getCustomerTransaction = `${API_URL}/transaction/get-transaction_by_user_id/?user_id=`;
// -------------------(START) CUSTOMER DELETE API-----------------------------------//
export const getCustomerDeleteReview = `${API_URL}/review/delete-review/?Review_id=`;

// #######################INSTRUCTOR#####################################
// -----------------------GET API----------------------------------------
export const getInstructorAppointmentId = `${API_URL}/appointment/get-appointment_by_instructor_id/?instructor_id=`;
export const getInstructorAssignedPackagesId = `${API_URL}/package_management/get-package_management_by_user_id/?user_id=`;
export const getInstructorProfile = `${API_URL}/user/get-user_by_id/?user_id=`;
export const getInstructorNews = `${API_URL}/blogs_and_news/get-blogs-and-news-by-user-id/?user_id=`;
export const getInstructorPackages = `${API_URL}/package/get-package-for-workshop/`;
export const getInstructorQuestionId = `${API_URL}/questions/get-questions_by_receiver_id/?receiver_id=`;
export const getInstructorWorkshop = `${API_URL}/workshop/get-workshop_by_workshop_instructor_id/?workshop_instructor_id=`;
// -----------------------UPDATE API----------------------------------------
export const updateInstructorProfile = `${API_URL}/user/update-user/`;
// -----------------------ADD API----------------------------------------

export const loginCheck = `${API_URL}/login/check-token/`;
export const logOut = `${API_URL}/login/logout/?id=`;

export const Update_MarkClicked = `${API_URL}/notification/get-update_notification_state/`;

// /------------------------NEW API-----------------------------------------------------------------------

export const getWishlistByVenueId = `${API_URL}/wishlist/get-get-wishlist-by-venue-id/?venue_id=`;
export const addCancellationPolicy = `${API_URL}/cancellation_policy/add-cancellation-policy/`;
export const addWeAreHiring = `${API_URL}/we_are_hiring/add-we-are-hiring/`;
export const addFaqPage = `${API_URL}/faq/add-faq/`;
export const getVenueImagesByVenueId = `${API_URL}/venue_images/get-venue-images-by-venue-id/?venue_id=`;
export const deleteMediaImage = `${API_URL}/venue_images/delete-venue-images/?venue_images_id=`;
export const getVenueImagesByImageId = `${API_URL}/venue_images/get-venue-images-by-id/?venue_images_id=`;
export const updateVenueImages = `${API_URL}/venue_images/update-venue-images/`;
export const getNewsPackageManagementForUserId = `${API_URL}/package_management/get-news-package-management-for-user-id/`;
export const getNewsPackageOfUserId = `${API_URL}/package_management/get-news-package-of-user-id/?user_id=`;
export const addPrivacyPolicies = `${API_URL}/privacy_policies/add-privacy-policies/`;
export const getCategories = `${API_URL}/categories/get-categories/`;
export const addTermsAndConditions = `${API_URL}/terms_and_conditions/add-terms-and-conditions/`;
export const getFAQByTempId = `${API_URL}/faq/get-faq_by_temp_id/?temp_id=`;
export const getFAQById = `${API_URL}/faq/get-faq_by_id/?FAQ_id=`;
export const getCommentByNewsAndBlogId = `${API_URL}/comment/get-comment-by-blog-and-news-id/?blog_and_news_id=`;
export const getSavedBlogAndNewsByBlogAndNewsId = `${API_URL}/saved_blogs_and_news/get-saved-blogs-and-news-by-blogs-and-news-id/?blogs_and_news_id=`;
export const deleteAboutUs = `${API_URL}/about_us/delete-about-us/?about_us_id=`;
export const deleteCancellationPolicyById = `${API_URL}/cancellation_policy/delete-cancellation-policy/?cancellation_policy_id=`;
export const deleteFAQById = `${API_URL}/faq/delete-faq/?FAQ_id=`;
export const deleteWeAreHiring = `${API_URL}/we_are_hiring/delete-we-are-hiring/?we_are_hiring_id=`;
export const deletePrivacyPolicy = `${API_URL}/privacy_policies/delete-privacy-policies/?privacy_policies_id=`;
export const deleteTermsAndConditions = `${API_URL}/terms_and_conditions/delete-terms-and-conditions/?terms_and_conditions_id=`;
export const getReviewById = `${API_URL}/review/get-review_by_id/?Review_id=`;
export const updateReviewStatuss = `${API_URL}/review/update-review-status/`;
export const getQuestionChatByQuestionId = `${API_URL}/questions_chat/get-questions-chat-by-question-id/?QuestionsChat_id=`;
export const updateAppointment = `${API_URL}/appointment/update-appointment/`;
export const updateAboutUs = `${API_URL}/about_us/update-about-us/`;
export const updateCancellationPolicy = `${API_URL}/cancellation_policy/update-cancellation-policy/`;
export const updateFaq = `${API_URL}/faq/update-faq/`;
export const updateWeAreHiring = `${API_URL}/we_are_hiring/update-we-are-hiring/`;
export const updatePrivacyPolicies = `${API_URL}/privacy_policies/update-privacy-policies/`;
export const updateTermsAndConditions = `${API_URL}/terms_and_conditions/update-terms-and-conditions/`;
export const getFaqByVenueId = `${API_URL}/faq/get-faq_by_venue_id/?FAQ_venue_id=`;
export const addBookingSlot = `${API_URL}/booking_slot/add-booking_slot/`;
export const getBookingSlotByVenueId = `${API_URL}/booking_slot/get-booking_slot_by_venue_id/?BookingSlot_venue_id=`;
export const getBookingSlotById = `${API_URL}/booking_slot/get-booking_slot_by_id/?BookingSlot_id=`;
export const deleteBookingSlotById = `${API_URL}/booking_slot/delete-booking_slot/?BookingSlot_id=`;
export const getFaqByWorkshopId = `${API_URL}/faq/get-faq_by_workshop_id/?FAQ_workshop_id=`;
export const getWorkshopSlotByWorkshopId = `${API_URL}/workshop_slot/get-workshop_slot_by_workshop_id/?WorkshopSlot_workshop_id=`;
export const deleteWorkshopSlotById = `${API_URL}/workshop_slot/delete-workshop_slot/?WorkshopSlot_id=`;
export const updateBookingSlot = `${API_URL}/booking_slot/update-booking_slot/`;
export const getActiveAppointmentByVenueId = `${API_URL}/appointment/get-active-appointment-by-venue-id/?venue_id=`;
export const getReviewByVenueId = `${API_URL}/review/get-review_by_venue_id/?venue_id=`;
export const getAppointmentByVenueId = `${API_URL}/appointment/get-appointment_by_venue_id/?venue_id=`;
export const addWorkshopImages = `${API_URL}/workshop_images/add-workshop-images/`;
export const getWorkshopImagesByWorkshopId = `${API_URL}/workshop_images/get-workshop-images-by-workshop-id/?workshop_id=`;
export const getWorkshopImagesById = `${API_URL}/workshop_images/get-workshop-images-by-id/?workshop_images_id=`;
export const updateWorkshopImages = `${API_URL}/workshop_images/update-workshop-images/`;
export const deleteWorkshopImagesById = `${API_URL}/workshop_images/delete-workshop-images/?workshop_images_id=`;
export const getActiveAppointmentByWorkshopId = `${API_URL}/appointment/get-active-appointment-by-workshop-id/?workshop_id=`;
export const getReviewByWorkshopId = `${API_URL}/review/get-review_by_workshop_id/?workshop_id=`;
export const getAppointmentByWorkshopId = `${API_URL}/appointment/get-appointment_by_workshop_id/?workshop_id=`;
export const getWishlistByWorkshopId = `${API_URL}/wishlist/get-get-wishlist-by-workshop-id/?workshop_id=`;
export const addQuestionsChat = `${API_URL}/questions_chat/add-questions-chat/`;
export const updateReview = `${API_URL}/review/update-review/`;
export const getSubCategories = `${API_URL}/sub_categories/get-sub_categories/`;
export const geLocationCity = `${API_URL}/location_city/get-location_city/`;
export const getLocationArea = `${API_URL}/location_area/get-location_area/`;
export const addWorkshopSlot = `${API_URL}/workshop_slot/add-workshop_slot/`;
export const getBookingByTempId = `${API_URL}/booking_slot/get-booking_by_temp_id/?temp_id=`;
export const getWorkshopSlotById = `${API_URL}/workshop_slot/get-workshop_slot_by_id/?WorkshopSlot_id=`;
export const getInstructorSlot = `${API_URL}/workshop_slot/get-booking-by-tempId/?temp_id=`;
export const UpdateWorkshopSlot = `${API_URL}/workshop_slot/update-workshop_slot/`;
export const addWorkshops = `${API_URL}/workshop/add-workshop/`;
export const getPackageForNews = `${API_URL}/package/get-package-for-news/`;
export const razorpayOrderCreate = `${API_URL}/razorpay/order/create/`;
export const addTransaction = `${API_URL}/transaction/add-transaction/`;
export const addPackageManagement = `${API_URL}/package_management/add-package_management/`;
export const deleteWorkshop = `${API_URL}/workshop/delete-workshop/?Workshop_id=`;
export const getBlogsAndNewsById = `${API_URL}/blogs_and_news/get-blog_and_news_by_id/?blog_and_news_id=`;
export const getPackageForWorkshop = `${API_URL}/package/get-package-for-workshop/`;
export const updateWorkshop = `${API_URL}/workshop/update-workshop/`;
export const addVenues = `${API_URL}/venue/add-venue/`;
export const deleteVenue = `${API_URL}/venue/delete-venue/?Venue_id=`;
export const getPackageForVenue = `${API_URL}/package/get-package-for-venue/`;
export const UpdateBookingSlot = `${API_URL}/booking_slot/update-booking_slot/`;
export const updateVenue = `${API_URL}/venue/update-venue/`;
export const getCancellationPolicy = `${API_URL}/cancellation_policy/get-cancellation-policy/`;
export const getFaqForFaqPage = `${API_URL}/faq/get-faq-for-faq-page/`;
export const updateToggleFaq = `${API_URL}/faq/update-toggle-faq/`;
export const getWeAreHiring = `${API_URL}/we_are_hiring/get-we-are-hiring/`;
export const getPrivacyPolicies = `${API_URL}/privacy_policies/get-privacy-policies/`;
export const getTermsAndConditions = `${API_URL}/terms_and_conditions/get-terms-and-conditions/`;
export const updateUser = `${API_URL}/user/update-user/`;
export const getWishlistByUserId = `${API_URL}/wishlist/get-wishlist_by_user_id/?user_id=`;
export const getUserById = `${API_URL}/user/get-user_by_id/?user_id=`;
export const getAppointmentByVenueOwnerId = `${API_URL}/appointment/get-appointment_by_venue_owner_id/?venue_owner_id=`;
export const getPackageManagementByUserId = `${API_URL}/package_management/get-package_management_by_user_id/?user_id=`;
export const getBlogsAndNewsByUserId = `${API_URL}/blogs_and_news/get-blogs-and-news-by-user-id/?user_id=`;
export const getQuestionByReceiverId = `${API_URL}/questions/get-questions_by_receiver_id/?receiver_id=`;
export const getVenueByVenueOwnerId = `${API_URL}/venue/get-venue_by_venue_owner_id/?venue_owner_id=`;

// -------------------------------------------------DASHBOARD API's-----------------------------------------------------

export const getDashboardNewListingsCounts = `${API_URL}/dashboard/get-new_listings_counts/`;
export const getDashboardNewsPackageCounts = `${API_URL}/dashboard/get-news-package-counts/`;
export const getDashboardActivePackageCounts = `${API_URL}/dashboard/get-active_package_counts/`;
export const getDashboardActiveAppointmentCounts = `${API_URL}/dashboard/get-active_appointment_counts/`;
export const getDashboardNewReviewCounts = `${API_URL}/dashboard/get-new_reviews_counts/`;
export const getDashboardNewMessageCounts = `${API_URL}/dashboard/get-new_message_counts/`;
export const getDashboardNewPendingPaymentsCounts = `${API_URL}/dashboard/get-new_pending_payments_counts/`;
export const getDashboardCompletedPaymentsCounts = `${API_URL}/dashboard/get-completed_payments_counts/`;
export const getDashboardTotalNewListingsCounts = `${API_URL}/dashboard/get-total_new_listings_counts/?how_want=`;
export const getDashboardTotalPackageCounts = `${API_URL}/dashboard/get-total_package_counts/?how_want=`;
export const getDashboardTotalAppointmentCounts = `${API_URL}/dashboard/get-total_appointment_counts/?how_want=`;
export const getDashboardTotalPackageEarning = `${API_URL}/dashboard/get-total_package_earning/?how_want=`;
export const getDashboardTotalEarning = `${API_URL}/dashboard/get-total_earning/?how_want=`;
export const getDashboardTotalReviewCounts = `${API_URL}/dashboard/get-total_reviews_counts/?how_want=`;
export const getDashboardTotalVisitCounts = `${API_URL}/dashboard/get-total_visit_count/?how_want=`;
export const getDashboardTotalShareCounts = `${API_URL}/dashboard/get-total_share_count/?how_want=`;
export const getDashboardTotalWishlistCounts = `${API_URL}/dashboard/get-total_wishlist_counts/?how_want=`;
export const getDashboardTotalSavedCounts = `${API_URL}/dashboard/get-total_saved_counts/?how_want=`;
export const getDashboardTotalUserCounts = `${API_URL}/dashboard/get-total_users_counts/?how_want=`;

export const getUserId = `${API_URL}/user/get-user_by_id/?user_id=`;
export const addAdminQuestion = `${API_URL}/questions_chat/add-questions-chat/`;
export const getAdminQuestionS = `${API_URL}/questions_chat/get-questions-chat-by-question-id/?QuestionsChat_id=`;
export const appointmentExcel = `${API_URL}/download/generate-appointments-data-excel/`;
export const transactionExcel = `${API_URL}/download/generate-transaction-data-excel/`;
export const workshopExcel = `${API_URL}/download/generate-workshop-data-excel/`;
export const venueExcel = `${API_URL}/download/generate-venue-data-excel/`;
export const assignedPackagesExcel = `${API_URL}/download/generate-package-management-data-excel/`;
export const newsExcel = `${API_URL}/download/generate-news-data-excel/`;
export const updateCustomerPayout = `${API_URL}/customer_pay_out/update-customer-payout/`;
export const updateInstructorPayout = `${API_URL}/instructor_or_owner_pay_out/update-instructor-or-owner-payout/`;
export const addworkshopExcel = `${API_URL}/workshop/import-workshop-excel/?workshop_instructor_id=`;

export const refreshWebsite = `${API_URL}/categories_page/set-categories-page-cache/`;

export const getPackageForAnnual = `${API_URL}/package/get-annual_hosting_package/?type=Workshop`;
